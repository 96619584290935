import { Card, CardContent, Typography } from "@mui/material";
import { supportURL } from "common/const";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchOrganisation } from "data/queries";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { organisationBillingInfo } from "./billingDetailsInfo";

const BillingDetailsPage = () => {
  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState);

  const organisationQuery = useQuery([QueryKeys.organisation, identityId], () =>
    fetchOrganisation()
  );

  return (
    <>
      <Card style={{}}>
        <CardContent>
          <Typography variant="h5">Billing Details</Typography>

          {organisationBillingInfo(organisationQuery)}

          <Typography variant="body1">
            Billing details are the same as your{" "}
            <Link to={Routes.orgGeneralSettings}>general settings</Link>
            .
            <br />
            Please contact{" "}
            <Link target="_blank" to={{ pathname: supportURL }}>
              support
            </Link>{" "}
            if you would like to send invoices and receipts to an alternative
            email or multiple email addresses.
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default BillingDetailsPage;
