import { Box } from "@mui/material";
import { DashboardCard } from "components/dashboard/dashboard-card";
import * as QueryKeys from "data";

import {
  fetchBillingUsage,
  fetchPaymentMethod,
  fetchSubscription,
} from "data/queries";

import {
  buildDashboardCardOverage,
  buildDashboardCardRenewal,
  buildDashboardCardSubscription,
  buildDashboardCardUsage,
} from "components/dashboard/utils-card";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { resolveIdentityId } from "utils/identity";

export const SubscriptionCards = () => {
  const history = useHistory();
  const [identityState, setIdentityState] = useIdentity();
  const identityId = resolveIdentityId(identityState);

  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription()
  );
  const billingUsageQuery = useQuery([QueryKeys.billingUsage, identityId], () =>
    fetchBillingUsage()
  );
  const paymentMethodQuery = useQuery(
    [QueryKeys.paymentMethod, identityId],
    () => fetchPaymentMethod()
  );

  const isLoading: boolean =
    subscriptionQuery.isLoading ||
    billingUsageQuery.isLoading ||
    paymentMethodQuery.isLoading;

  const isEnterprise: boolean =
    subscriptionQuery.isSuccess &&
    !!subscriptionQuery.data.activeSubscription &&
    subscriptionQuery.data.activeSubscription.plan.isEnterprise;

  const isPaymentMyob: boolean =
    (subscriptionQuery.isSuccess &&
      !!subscriptionQuery.data.activeSubscription &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      !!subscriptionQuery.data.activeSubscription &&
      subscriptionQuery.data.activeSubscription.isMyob);

  const hasScheduledDowngrade: boolean = subscriptionQuery.data
    ? !!subscriptionQuery.data.activeSubscription &&
      subscriptionQuery.data.activeSubscription.nextSub != null
    : true;

  const creditAmount: number = billingUsageQuery.data
    ? billingUsageQuery.data.allowance
    : 0;

  const dashboardCardRenewal = buildDashboardCardRenewal(
    subscriptionQuery,
    isLoading,
    history
  );

  const dashboardCardUsage = buildDashboardCardUsage(
    billingUsageQuery,
    creditAmount,
    isLoading,
    history
  );
  const dashboardCardSubscription = buildDashboardCardSubscription(
    subscriptionQuery,
    history,
    isLoading,
    isEnterprise,
    isPaymentMyob,
    true
  );

  const DasboardCarOverage = buildDashboardCardOverage(
    billingUsageQuery,
    history,
    isLoading,
    isEnterprise
  );

  return (
    <>
      <ReactQueryErrorWrapper
        queries={[subscriptionQuery, billingUsageQuery, paymentMethodQuery]}
        mutations={[]}
      />

      <Box
        style={{
          display: "flex",
          width: "100%",
          gap: "16px",
          padding: "0px",
        }}
      >
        <DashboardCard
          cardState={dashboardCardRenewal}
          disableActionBtn={true}
        />

        <DashboardCard
          cardState={dashboardCardSubscription}
          disableActionBtn={
            hasScheduledDowngrade || !subscriptionQuery.data?.activeSubscription
          }
        />

        <DashboardCard cardState={DasboardCarOverage} />

        <DashboardCard cardState={dashboardCardUsage} />
      </Box>
    </>
  );
};
