import MapboxDraw from "@mapbox/mapbox-gl-draw";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIdentity } from "contexts/identity-context";
import { createClipConfig } from "data/mutations";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { MapRef } from "react-map-gl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { CircleMode, SimpleSelectMode } from "./circle-lib";
import { ClipConfiguration } from "./models";
import { NewClip } from "./steps/step-0-new-clip/new-clip";
import { DefineBoundary } from "./steps/step-1-define-boundary/define-boundary";
//@ts-ignore
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
//@ts-ignore
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchClips } from "data/queries";
import { useLocation } from "react-router-dom";
import { resolveIdentityId } from "utils/identity";
import * as Routes from "../../../../routes";
import { zoomToFeatureCollection } from "../utils";
import { drawStyles } from "./draw-styles";
import { ChooseData } from "./steps/step-2-choose-data/choose-data";
import { FinaliseClip } from "./steps/step-3-finalise/finalise-clip";
import { ClipSummary } from "./steps/step-4-summary/clip-summary";
const stepsMapping = [
  {
    stepName: "New Clip",
    element: NewClip,
  },
  {
    stepName: "Define the clip boundary",
    element: DefineBoundary,
  },
  {
    stepName: "Choose Data",
    element: ChooseData,
  },
  {
    stepName: "Finalise Clip",
    element: FinaliseClip,
  },
  {
    stepName: "Clip Summary",
    element: ClipSummary,
  },
];

export interface ClipStepsControlProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  maxSteps: number;
}

export interface ClipConfigProps {
  clipConfig: ClipConfiguration;
  setClipConfig: React.Dispatch<React.SetStateAction<ClipConfiguration>>;
  saveClipConfig: (cfg?: ClipConfiguration) => void;
  resetClipConfig: () => void;
  mapRef: Ref<MapRef>;
  draw: MapboxDraw;
  clips: ClipConfiguration[];
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

interface ClipMenuProps {
  setClipOpen: (b: boolean) => void;
  mapRef: Ref<MapRef>;
  initialClipId?: string;
}

export const ClipMenu = (props: ClipMenuProps) => {
  const [hasShownInitialClipAlready, setHasShownInitialClipAlready] =
    useState<boolean>(false);

  const [draw, setDraw] = useState<MapboxDraw>(
    new MapboxDraw({
      boxSelect: false,
      keybindings: false,
      touchEnabled: false,

      displayControlsDefault: false,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        simple_select: SimpleSelectMode,
        draw_rectangle: DrawRectangle,
        static: StaticMode,
      },
      styles: drawStyles,
    })
  );

  dayjs.extend(utc);
  const initialClipConfig: ClipConfiguration = {
    id: uuidv4(),
    name: "",
    activeStep: 0,
    status: "Draft",
    date: dayjs.utc().format(),
  };

  const [expanded, setExpanded] = useState(
    !!props.initialClipId && !hasShownInitialClipAlready
  );

  const [clipConfig, setClipConfig] =
    useState<ClipConfiguration>(initialClipConfig);
  const [activeStep, setActiveStep] = useState(clipConfig.activeStep);
  const [identityState] = useIdentity();
  const queryClient = useQueryClient();

  const identityId = resolveIdentityId(identityState);

  const { enqueueQueryFailed, enqueueMutationFailed } = useCustomSnackbars();

  const clipsQuery = useQuery(
    [QueryKeys.clipsKey, identityId],
    () => fetchClips(),
    {
      onSuccess: (clips: ClipConfiguration[]) => {
        if (!hasShownInitialClipAlready) {
          const initialClip = clips.find(
            (clip) => clip.id === props.initialClipId
          );
          if (initialClip && !hasShownInitialClipAlready) {
            setActiveStep(initialClip.activeStep);
            setClipConfig(initialClip);
            setHasShownInitialClipAlready(true);
          }
        }
      },
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
      refetchInterval: expanded ? 5000 : 30000,
      refetchIntervalInBackground: expanded,
    }
  );

  useEffect(() => {
    setClipConfig((prevState) => {
      return {
        ...prevState,
        activeStep: activeStep,
      };
    });
    saveClipConfig();

    if (!draw) {
      return;
    }

    if (activeStep > 1) {
      draw.changeMode(MapboxDraw.constants.modes.STATIC);
    } else if (activeStep === 1) {
      draw.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT);
    }
  }, [activeStep]);

  useEffect(() => {
    if (!props.mapRef) return;
    //@ts-ignore
    if (!props.mapRef.current.hasControl(draw)) {
      //@ts-ignore
      props.mapRef?.current.addControl(draw);
    }
  }, [props.mapRef]);

  useEffect(() => {
    if (!draw) {
      return;
    }
    if (!clipConfig.geometry) {
      return;
    }
    clipConfig.geometry.features.map((f) => {
      draw.add(f);
    });

    zoomToFeatureCollection(props.mapRef, clipConfig.geometry);
  }, [clipConfig.id]);

  const clipQueryKey = [QueryKeys.clipsKey, identityId];

  const clipsCreateMutation = useMutation(
    (clipConfig: ClipConfiguration) => createClipConfig(clipConfig),
    {
      onMutate: async (newConfig) => {
        await queryClient.cancelQueries(clipQueryKey);
        const previousConfigs: ClipConfiguration[] | undefined =
          queryClient.getQueryData(clipQueryKey);

        queryClient.setQueryData(
          clipQueryKey,
          (previousConfigs: ClipConfiguration[] | undefined) => {
            let updateFlag = false;
            const updatedPreviousConfigs = previousConfigs?.map((x) => {
              if (x.id === newConfig.id) {
                updateFlag = true;
                return newConfig;
              }
              return x;
            });
            let newCache = updatedPreviousConfigs ?? [];
            if (!updateFlag) {
              newCache.push(newConfig);
            }
            return newCache;
          }
        );
        return { previousConfigs };
      },
      onError: (err: Error, newConfig, context) => {
        queryClient.setQueriesData(clipQueryKey, context?.previousConfigs);
      },
      onSettled: () => {
        queryClient.invalidateQueries(clipQueryKey);
      },
    }
  );

  const saveClipConfig = (config?: ClipConfiguration) => {
    const cfg = config ?? clipConfig;
    if (clipConfig.name && activeStep > 0) {
      clipsCreateMutation.mutate(cfg);
    }
  };

  const resetClipConfigForm = () => {
    draw.deleteAll();
    setActiveStep(0);
    setClipConfig(initialClipConfig);
  };

  const onClose = () => {
    saveClipConfig();
    resetClipConfigForm();
    if (activeStep === 0) {
      setExpanded(false);
      props.setClipOpen(false);
    }
  };

  useEffect(() => {
    if (!expanded) onClose();
  }, [expanded]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === Routes.dataExplorerClip) {
      setExpanded(true);
    }
  }, [location.pathname]);

  const classes = useStyles();
  return (
    <>
      {!expanded && (
        <Card className={classes.minimized}>
          <CardActionArea
            onClick={() => {
              setExpanded(true);
              props.setClipOpen(true);
            }}
            disabled={clipsCreateMutation.isLoading || clipsQuery.isLoading}
          >
            <CardHeader
              sx={{
                width: "100%",

                paddingBottom: "16px !important",
              }}
              action={
                <IconButton
                  data-cy="open-clip-window"
                  disabled={
                    clipsCreateMutation.isLoading || clipsQuery.isLoading
                  }
                >
                  <AddIcon />
                </IconButton>
              }
              title="Clip"
            />
          </CardActionArea>
        </Card>
      )}
      {expanded && (
        <>
          {clipsQuery.isLoading && (
            <Card className={classes.expanded}>
              <CardContent
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  height: "calc(80vh - 95px)",

                  display: "flex",
                  flexDirection: "column",
                  "&:last-child": {
                    paddingBottom: 1,
                  },
                }}
              >
                <Skeleton width={"100%"} height={"100%"}></Skeleton>
              </CardContent>
            </Card>
          )}
          {clipsQuery.isSuccess && (
            <Card className={classes.expanded}>
              <CardHeader
                sx={{ width: "100%" }}
                action={
                  <IconButton onClick={onClose}>
                    <CloseIcon></CloseIcon>
                  </IconButton>
                }
                title="Clip"
                subheader={
                  clipConfig.name && (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="caption" color="gray">
                        {`Clip Id: ${clipConfig.id}`}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          navigator.clipboard.writeText(clipConfig.id)
                        }
                      >
                        <ContentCopyIcon
                          sx={{ fontSize: "15px", color: "gray" }}
                        />
                      </IconButton>
                    </Stack>
                  )
                }
              />
              <CardContent
                sx={{
                  width: "100%",
                  // height: "calc(80vh - 95px)",

                  display: "flex",
                  flexDirection: "column",
                  "&:last-child": {
                    paddingBottom: 1,
                  },
                }}
              >
                {React.createElement(stepsMapping[activeStep].element, {
                  activeStep: activeStep,
                  setActiveStep: setActiveStep,
                  maxSteps: stepsMapping.length - 1,
                  clipConfig: clipConfig,
                  setClipConfig: setClipConfig,
                  saveClipConfig: saveClipConfig,
                  mapRef: props.mapRef,
                  draw: draw,
                  clips: clipsQuery.data ?? [],
                  setExpanded: setExpanded,
                  resetClipConfig: resetClipConfigForm,
                })}
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  expanded: {
    maxHeight: "100%",
    width: "430px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    paddingBottom: 0,
  },
  minimized: {
    width: "116px",

    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
