import { Cancel } from "@mui/icons-material";
import { Alert, Box, Button } from "@mui/material";

import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { BaseState } from "common/models";
import ConfirmationModal from "components/modals/confirmation-modal";
import { NameEmailAvatarRound } from "components/shared/shared";
import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";
import { UserMembership } from "data/models";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { makeApiRequestToUrl } from "utils/api-client";

export interface InvitationListState extends BaseState {
  invitationList: UserMembership[];
}

export type DeleteModalState = {
  isOpen: boolean;
  invitation: UserMembership;
};

type Props = {
  invitationsState: InvitationListState;
  setInvitationsState: Dispatch<SetStateAction<InvitationListState>>;
};

const emptyInvitation: UserMembership = {
  organisationId: "",
  userMemberId: "",
  status: "",
  organisationName: "",
  userFullName: "",
  userEmail: "",
  organisationEmail: "",
  userFirstName: "",
  userLastName: "",
};

export const InvitationList = ({
  invitationsState,
  setInvitationsState,
}: Props) => {
  const [modal, setModal] = useState<DeleteModalState>({
    isOpen: false,
    invitation: emptyInvitation,
  });
  const [identity] = useIdentity();
  const [alertOpen, setAlertOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const user = GetUserIfLoggedIn(identity);

  const handleClickOpen = (invitation: UserMembership) => {
    setModal({ invitation: invitation, isOpen: true });
  };

  useEffect(() => {
    if (invitationsState.message === "") return;
    setAlertOpen(true);
    const timeId = setTimeout(() => {
      setAlertOpen(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [invitationsState.message]);

  const handleClose = () => {
    setModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
      };
    });
  };

  const handleDelete = async () => {
    setInvitationsState((prevState) => {
      return {
        ...prevState,
        message: "",
        isError: false,
        isLoading: true,
      };
    });
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/v2/organisation/membership?organisationId=${
        modal.invitation.organisationId
      }&userMemberId=${encodeURIComponent(modal.invitation.userMemberId)}`,
      "DELETE"
    );
    if (response.status !== 200) {
      const errorMessage = (await response.json()).messages.join(". ");
      setInvitationsState((prevState) => {
        return {
          ...prevState,
          message: errorMessage,
          isError: true,
          isLoading: false,
        };
      });
      setModal({
        invitation: emptyInvitation,
        isOpen: false,
      });
    } else {
      const invitationList: UserMembership[] = invitationsState.invitationList;
      const newList: UserMembership[] = _.reject(
        invitationList,
        (invitation) => invitation.userEmail === modal.invitation.userEmail
      );
      setModal({
        invitation: emptyInvitation,
        isOpen: false,
      });
      setInvitationsState((prevState) => {
        return {
          ...prevState,
          message: "User has been deleted from the organisation",
          invitationList: [...newList],
          isError: false,
          isLoading: false,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      flex: 2,
      field: "name",
      headerName: "Members",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NameEmailAvatarRound
              name={params.value}
              variant={"circular"}
              isLoading={false}
              email={params.row.email}
            />
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: "role",
      headerName: "Role",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          startIcon={<Cancel />}
          variant="outlined"
          color="primary"
          size="medium"
          disabled={!user || user.email === params.row.email}
          style={{ marginLeft: 16 }}
          onClick={() => {
            handleClickOpen({
              organisationId: params.row.orgId,
              userMemberId: params.row.id,
              status: params.row.status,
              organisationName: params.row.orgName,
              userFullName: params.row.name,
              userEmail: params.row.email,
              organisationEmail: params.row.orgEmail,
              userFirstName: "",
              userLastName: "",
            });
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  const developerNameOrEmail: string = modal.invitation.userFullName
    ? modal.invitation.userFullName
    : modal.invitation.userEmail;
  return (
    <Box marginY={2}>
      <Box hidden={!alertOpen} marginBottom={1} pt={2}>
        <Alert
          variant="filled"
          severity={invitationsState.isError ? "error" : "success"}
        >
          {invitationsState.message}
        </Alert>
      </Box>
      <ConfirmationModal
        isLoading={invitationsState.isLoading}
        isError={invitationsState.isError}
        open={modal.isOpen}
        header={"Confirm Member Removal"}
        body={`Are you sure you want to remove "${developerNameOrEmail}" from "${modal.invitation.organisationName}", by doing this they will no longer be able to access this org unless invited again.`}
        leftButtonText={"Remove member"}
        leftButtonColor="primary"
        rightButtonText={"Cancel"}
        rightButtonColor="warning"
        handleClose={() => handleClose()}
        handleLeftButton={() => handleDelete()}
        handleRightButton={() => handleClose()}
      ></ConfirmationModal>

      <DataGridPremium
        autoHeight={true}
        disableRowSelectionOnClick={true}
        hideFooterSelectedRowCount={true}
        rowHeight={72}
        rows={invitationsToRows(invitationsState.invitationList)}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        loading={invitationsState.isLoading}
      />
    </Box>
  );
};

const invitationsToRows = (invitationList: UserMembership[]) => {
  const rows = invitationList
    .filter((i) => i.status === "Accepted" || i.status === "Pending")
    .sort((a: UserMembership, b: UserMembership) =>
      a.userFullName.localeCompare(b.userFullName)
    )
    .map((key) => {
      return {
        id: key.userMemberId,
        orgId: key.organisationId,
        orgName: key.organisationName,
        orgEmail: key.organisationEmail,
        members: key.userFullName,
        email: key.userEmail,
        name: key.userFullName,
        role: "Member",
        status: key.status,
      };
    });
  return rows;
};
