import * as QueryKeys from "data";

// reactQuery
import { fetchApiKeys, fetchSubscription, fetchUsage } from "data/queries";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";

import { IdentityState } from "contexts/identity-context";

export const useFetchSubscription = (identityState: IdentityState) => {
  const identityId = resolveIdentityId(identityState);

  return useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription()
  );
};

export const useFetchApiKeys = (identityState: IdentityState) => {
  const identityId = resolveIdentityId(identityState);

  return useQuery({
    queryKey: [QueryKeys.apiKeys, identityId],
    queryFn: () => fetchApiKeys(),
  });
};

export const useFetchUsageGraph = (
  identityState: IdentityState,
  startTime: number,
  endTime: number,
  enabled?: boolean,
  overrideIntervalTime?: string
) => {
  const identityId = resolveIdentityId(identityState);

  return useQuery({
    queryKey: [QueryKeys.usageGraph, identityId, startTime, endTime],
    queryFn: () => fetchUsage(startTime, endTime, overrideIntervalTime),
    enabled: enabled,
  });
};
