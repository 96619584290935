import * as Routes from "../../routes";
interface item {
  name: string;
  link?: string;
  notImplemented?: boolean;
  target?: string;
  disabled?: boolean;
}
export interface SideBarSubMenuItemProps {
  name: string;
  link?: string;
  notImplemented?: boolean;
  target?: string;
  Icon?: React.ComponentType<any>;
  items?: item[];
  disabled?: boolean;
}

export interface SideBarSubMenuProps {
  menuItems?: SideBarSubMenuItemProps[];
}

import { useTheme } from "@mui/material";
import {
  BookOpenText,
  Bot,
  ChartColumnIncreasing,
  CodeXml,
  Database,
  House,
  Map,
  NotebookText,
  Settings,
  Shield,
  SquareScissors,
  WandSparkles,
} from "lucide-react";

export const getMenuItems = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  var menuItems = [
    {
      name: "Home",
      link: Routes.hub,
      Icon: House,
    },
    {
      name: "Explorer",
      link: Routes.dataExplorer,
      Icon: Map,
    },
    {
      name: "Clip",
      link: Routes.dataExplorerClip,
      Icon: SquareScissors,
    },
    {
      name: "Catalogue",
      link: Routes.dataCatalogue,
      Icon: BookOpenText,
    },
    {
      name: "Datasets",
      link: "",
      Icon: Database,
      subMenus: [
        { name: "Standard", link: Routes.dataDownload },
        { name: "My Custom", link: Routes.dataCustomDownload },
      ],
    },
    {
      name: "Batch",
      link: Routes.batchBatches,
      Icon: Bot,
    },
    {
      name: "Apps",
      link: "",
      Icon: CodeXml,
      subMenus: [
        { name: "Dashboard", link: Routes.developerDashboard },
        { name: "API Keys", link: Routes.developerApiKeys },
      ],
    },
    {
      name: "Demos",
      link: Routes.demosPage,
      Icon: WandSparkles,
    },
    {
      name: "Documentation",
      link: Routes.docsPage,
      Icon: NotebookText,
    },
    {
      name: "Usage",
      link: Routes.developerUsage,
      Icon: ChartColumnIncreasing,
    },

    {
      name: "Settings",
      link: "",
      Icon: Settings,
      subMenus: [
        {
          name: "General",
          link: Routes.orgGeneralSettings,
        },
        {
          name: "Pending Invitations",
          link: Routes.userPendingInvitations,
        },
        {
          name: "Members",
          link: Routes.orgMembers,
        },
        {
          name: "Subscription",
          link: Routes.orgSubscription,
        },
        {
          name: "Overage",
          link: Routes.orgOverage,
        },
        {
          name: "Billing",
          link: Routes.orgBillingDetails,
        },
      ],
    },

    {
      name: "Admin",
      link: "",
      Icon: Shield,
      adminOnly: true,
      subMenus: [
        { name: "Organisations", link: Routes.adminOrganisations },
        { name: "Demos", link: Routes.adminDemoManagement },
        { name: "Users", link: Routes.adminUsers },
        { name: "Datasets", link: Routes.adminDatasets },
        { name: "Custom Datasets", link: Routes.adminViewCustomDatasets },
        { name: "Releases", link: Routes.adminReleases },
        { name: "Clip Reporting", link: Routes.adminClipReporting },
      ],
    },
  ];

  return menuItems;
};
