import { IdentityState } from "contexts/identity-context";
import { useEffect } from "react";
import { FileWithPath } from "react-dropzone";
import { makeApiRequestPostToUrl } from "utils/api-client";

import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import * as Papa from "papaparse";
import React, { useState } from "react";

export const CustomAlert = ({
  props,
  files,
}: {
  props: BatchConfigProps;
  files: any;
}) => {
  const [open, setOpen] = useState(
    !props.batchConfig.hubInfo.headersMessageAck
  );

  const [open2, setOpen2] = useState(
    !props.batchConfig.hubInfo.headersMessageAck2
  );

  useEffect(() => {
    if (files.length > 0) {
      setOpen(true);
      setOpen2(true);

      props.setBatchConfig((prev) => ({
        ...prev,
        hubInfo: {
          ...prev.hubInfo,
          headersMessageAck: false,
          headersMessageAck2: false,
        },
      }));
    }
  }, [files]);

  const handleClose = () => {
    props.setBatchConfig((prev) => ({
      ...prev,
      hubInfo: {
        ...prev.hubInfo,
        headersMessageAck: true,
      },
    }));

    setOpen(false);
  };

  const handleClose2 = () => {
    props.setBatchConfig((prev) => ({
      ...prev,
      hubInfo: {
        ...prev.hubInfo,
        headersMessageAck2: true,
      },
    }));

    setOpen2(false);
  };

  let matchType;
  if (props.batchConfig.type == "addressGeocoder") {
    matchType = props.batchConfig.parameters.matchType;
  } else {
    matchType = "reverseGeocoder";
  }

  let message;
  switch (matchType) {
    case "address":
      message =
        "Please ensure your input includes either 'Address' for a single column OR 'Street No', 'Street Name', 'Locality', and either 'Postcode' or 'State' for multiple columns.";
      break;
    case "street":
      message =
        "Please ensure your input includes either 'Address' for a single column OR 'Street Name', 'Locality', and either 'Postcode' or 'State' for multiple columns.";
      break;
    case "locality":
      message =
        "Please ensure your input includes either 'Address' for a single column OR 'Locality', and either 'Postcode' or 'State' for multiple columns.";
      break;
    case "reverseGeocoder":
      message = "Please ensure your input includes ‘longitude’ and ‘latitude’.";
      break;
  }

  return (
    <>
      {open && (
        <Alert
          severity="info"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Important: Column Headers</AlertTitle>
          Please ensure your file includes column headers. If no headers are
          found, the first row of your file will be used as the header.
        </Alert>
      )}

      {open2 && (
        <Alert
          severity="info"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose2}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>
            Required Input: Please ensure your input columns are mapped
            correctly
          </AlertTitle>
          {message}
        </Alert>
      )}
    </>
  );
};

export function useProcessUploadedFile(
  files: FileWithPath[],
  uploadFiles: (bucket_name: any) => Promise<void>,
  setHeaders: React.Dispatch<React.SetStateAction<InputBatchConfigFormat[]>>,
  setRowCount: React.Dispatch<React.SetStateAction<number>>,
  batchConfig: any,
  setBatchConfig: any,
  setIsEmptyFile: any
) {
  useEffect(() => {
    // Check if any files were selected
    if (files.length == 0) {
      return;
    }

    const fileToUpload = files[0];

    // Check if the file is empty
    const emptyReader = new FileReader();
    emptyReader.onload = () => {
      const content: string | ArrayBuffer | null = emptyReader.result;
      const rows: string[] | undefined = content?.toString().split("\n");

      if (!rows || rows.length <= 1) {
        setIsEmptyFile(true);
        return;
      }

      // File is not empty, upload and process it
      uploadFiles(import.meta.env.VITE_BATCH_UI_BUCKET_NAME);

      const fileHeaders = rows[0].split(",");

      // Parse CSV headers using papaparse
      Papa.parse(fileToUpload, {
        complete: (result) => {
          setHeaders(
            result.data.length > 0
              ? (result.data[0] as string[]).map((name: any) => ({
                  name: name.trim(),
                  apiParameter: "Ignore",
                }))
              : []
          );
        },
      });

      setRowCount(rows.length - 1);

      // Extract sample rows
      if (batchConfig.hubInfo.sample.rows.length === 0) {
        extractSampleRows(files, batchConfig, setBatchConfig);
      }
    };
    emptyReader.readAsText(fileToUpload);
  }, [files]);
}

export const deleteBucketObject = async (
  objectKey: string,
  bucket: string,
  identity: IdentityState
) => {
  const response = await makeApiRequestPostToUrl(
    `${import.meta.env.VITE_DELIVERY_API_URL}`,
    `/deleteObject`,
    "POST",
    {
      bucket: bucket,
      key: objectKey,
    }
  );

  return await response.json();
};

import { BatchConfig, Format } from "pages/geoscape-batch/models";
import {
  BatchConfigProps,
  InputBatchConfigFormat,
} from "pages/geoscape-batch/types";
import {
  batchOutputGeocode,
  batchOutputReverseGeocode,
} from "pages/geoscape-batch/utils";
import { useMutation } from "react-query";
import { extractSampleRows } from "./upload-input-file-page";

export const useDeleteObjectMutation = (
  files: any,
  removeFile: any,
  props: any,
  identityState: any,
  setHeaders: any,
  setIsEmptyFile: any
) => {
  const deleteObjectMutation = useMutation(
    (name: string) =>
      deleteBucketObject(
        name,
        import.meta.env.VITE_BATCH_UI_BUCKET_NAME,
        identityState
      ),
    {
      onSuccess: () => {
        if (files[0].path) removeFile(files[0].path);

        props.setBatchConfig((prev: BatchConfig) => ({
          ...prev,
          input: {
            ...prev.input,
            format: [""],
            file: "",
          },
          hubInfo: {
            ...prev.hubInfo,
            csvHeaders: [],
            files: [],
            sample: {
              ...prev.hubInfo.sample,
              rows: [],
              request: [],
              response: [],
            },

            checkedItems:
              props.batchConfig.type == "addressGeocoder"
                ? batchOutputGeocode.properties
                : batchOutputReverseGeocode.properties,
          },
          output: {
            format: [],
          },
        }));

        setHeaders([]);

        setIsEmptyFile(false);
      },
    }
  );

  return deleteObjectMutation;
};

export function getValidation(batchConfig: BatchConfig): any {
  if (batchConfig.input?.format) {
    const matchTerms = batchConfig.input?.format?.map(
      (item: Format) => item.apiParameter
    );

    let matchType;

    if (batchConfig.type == "addressGeocoder") {
      matchType = batchConfig.parameters.matchType;
    } else {
      matchType = "reverseGeocoder";
    }

    switch (matchType) {
      case "address":
        return (
          matchTerms.includes("address") ||
          ((matchTerms.includes("streetNumber1") ||
            matchTerms.includes("streetNumber2")) &&
            matchTerms.includes("streetName") &&
            matchTerms.includes("localityName") &&
            (matchTerms.includes("postcode") ||
              matchTerms.includes("stateTerritory")))
        );
      case "street":
        return (
          matchTerms.includes("address") ||
          (matchTerms.includes("streetName") &&
            matchTerms.includes("localityName") &&
            (matchTerms.includes("postcode") ||
              matchTerms.includes("stateTerritory")))
        );
      case "locality":
        return (
          matchTerms.includes("address") ||
          (matchTerms.includes("localityName") &&
            (matchTerms.includes("postcode") ||
              matchTerms.includes("stateTerritory")))
        );
      case "reverseGeocoder":
        return (
          matchTerms.includes("latitude") && matchTerms.includes("longitude")
        );
      default:
        return false;
    }
  }
  return false;
}
