import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  CardHeader,
  Skeleton,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import { GALTextInput } from "components/library/gal-text-input";
import { PredictiveAddressSearch } from "components/organisations/pav-address-input";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { industries } from "components/user/sign-in/states";
import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { Organisation } from "data/models";
import { editOrganisation } from "data/mutations";
import { fetchOrganisation } from "data/queries";
import { Form, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { object, string } from "yup";

const orgCreationValidator = object({
  name: string().required("Organisation name is required"),
  email: string().email("Enter a valid email").required("Email is required"),
  abn: string()
    .matches(/^\d+$/, "ABN must be 11 digits")
    .min(11, "ABN must be 11 digits")
    .max(11, "ABN must be 11 digits")
    .nullable(true),
  address: string().nullable(true),
  phoneNumber: string()
    .matches(
      /^\d+$/,
      "Please provide a valid Australian phone number without the country code"
    )
    .min(
      10,
      "Please provide a valid Australian phone number without the country code"
    )
    .max(
      10,
      "Please provide a valid Australian phone number without the country code"
    )
    .nullable(true),
  industry: string()
    .oneOf([null, ...industries])
    .nullable(true),
  id: string().nullable(true),
});

export const GeneralSettings = () => {
  const classes = useStyles();

  const [identity, setIdentity] = useIdentity();
  const identityId = resolveIdentityId(identity);

  const queryClient = useQueryClient();
  const organisationQuery = useQuery([QueryKeys.organisation, identityId], () =>
    fetchOrganisation()
  );
  const organisationMutation = useMutation(
    (organisation: Organisation) => editOrganisation(organisation),
    {
      onSuccess: (updatedOrganisation) => {
        queryClient.setQueryData(
          [QueryKeys.organisation, identityId],
          updatedOrganisation
        );

        setIdentity((prevIdentity) => {
          const user = GetUserIfLoggedIn(prevIdentity);
          if (user) {
            return {
              ...user,
              organisations: user.organisations.map((org) => {
                if (org.active) {
                  return {
                    ...org,
                    name: updatedOrganisation.name,
                  };
                } else {
                  return org;
                }
              }),
            };
          } else {
            return prevIdentity;
          }
        });

        handleEdit();
      },
    }
  );

  // Edit Organisation State
  const [editState, setEditSate] = useState(false);

  const handleEdit = () => {
    setEditSate(!editState);
  };

  const CancelChanges = async () => {
    setEditSate(!editState);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "700px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          position: "relative",
          top: "-70px",
          gap: "16px",
        }}
      >
        <Button
          onClick={handleEdit}
          variant="contained"
          size="large"
          className={editState ? classes.noneDisplay : ""}
          startIcon={<EditIcon />}
        >
          Edit General Settings
        </Button>
      </Box>

      <Formik<Organisation>
        enableReinitialize={true}
        initialValues={{
          name: organisationQuery.data ? organisationQuery.data?.name : "",
          email: organisationQuery.data ? organisationQuery.data?.email : "",
          address: organisationQuery.data
            ? organisationQuery.data?.address
            : "",
          industry: organisationQuery.data
            ? organisationQuery.data?.industry
            : "",
          phoneNumber: organisationQuery.data
            ? organisationQuery.data?.phoneNumber
            : "",
          abn: organisationQuery.data ? organisationQuery.data?.abn : "",
        }}
        onSubmit={async (values) => {
          organisationMutation.mutate({
            id: organisationQuery.data?.id,
            ...values,
          });
        }}
        validationSchema={orgCreationValidator}
      >
        {({
          values,
          handleChange,
          isSubmitting,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }: any) => (
          <Form>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "10px",
                gap: "20px",
              }}
            >
              <ReactQueryErrorWrapper
                queries={[organisationQuery]}
                mutations={[organisationMutation]}
              />

              <Card>
                <CardHeader
                  title="General"
                  style={{ padding: "5" }}
                  variant="h5"
                />
                {organisationQuery.isLoading ? (
                  <CardContent>
                    <Skeleton
                      variant="rectangular"
                      height={56}
                      width={"100%"}
                      style={{ marginBottom: "20px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={56}
                      width={"100%"}
                      style={{ marginBottom: "20px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={56}
                      width={"100%"}
                    />
                  </CardContent>
                ) : (
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      // height: "100%",
                      padding: "10px",
                      // alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <GALTextInput
                      id="name"
                      name="name"
                      label="Organisation Name"
                      type="text"
                      margin="normal"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isError={touched.name && Boolean(errors.name)}
                      errorText={errors.name}
                      wasTouched={touched.name}
                      variant="outlined"
                      inputProps={{
                        readOnly: organisationMutation.isLoading || !editState,
                      }}
                      helperText={touched.name && errors.name}
                      characterLimit={64}
                      disabled={
                        !editState ||
                        organisationQuery.isLoading ||
                        isSubmitting
                      }
                      fullWidth
                      sx={{ padding: "0px", margin: "0px" }}
                    />
                    <InputField
                      id="abn"
                      label={"ABN"}
                      name="abn"
                      editState={editState}
                      loadingState={organisationQuery.isLoading || isSubmitting}
                      value={values.abn}
                      handleChange={handleChange}
                      type={"string"}
                      inputProps={{ maxLength: 11 }}
                      error={touched.abn && Boolean(errors.abn)}
                      helperText={touched.abn && errors.abn}
                    />
                    {values.industry !== undefined && (
                      <IndustryAutoComplete
                        size="medium"
                        value={values.industry}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        readOnly={
                          !editState ||
                          organisationQuery.isLoading ||
                          isSubmitting
                        }
                        disabled={
                          !editState ||
                          organisationQuery.isLoading ||
                          isSubmitting
                        }
                        error={
                          touched.industry !== null &&
                          touched.industry &&
                          Boolean(errors.industry)
                        }
                        helperText={touched.industry && errors.industry}
                      />
                    )}
                  </CardContent>
                )}
              </Card>
              <Card>
                <CardHeader
                  title="Contact"
                  style={{ padding: "5" }}
                  variant="h5"
                />
                {organisationQuery.isLoading ? (
                  <CardContent>
                    <Skeleton
                      variant="rectangular"
                      height={56}
                      width={"100%"}
                      style={{ marginBottom: "20px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={56}
                      width={"100%"}
                      style={{ marginBottom: "20px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={56}
                      width={"100%"}
                    />
                  </CardContent>
                ) : (
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      padding: "10px",
                      gap: "20px",
                    }}
                  >
                    <InputField
                      id="phoneNumber"
                      label={"Phone"}
                      name="phoneNumber"
                      inputProps={{ maxLength: 10 }}
                      editState={editState}
                      loadingState={organisationQuery.isLoading || isSubmitting}
                      value={values.phoneNumber}
                      handleChange={handleChange}
                      type={"string"}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                    <InputField
                      id="email"
                      label={"Email"}
                      name="email"
                      inputProps={{ maxLength: 254 }}
                      editState={editState}
                      loadingState={organisationQuery.isLoading || isSubmitting}
                      value={values.email}
                      handleChange={handleChange}
                      type={"email"}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    {values.address !== undefined && (
                      <PredictiveAddressSearch
                        disabled={organisationQuery.isLoading || !editState}
                        readOnly={organisationQuery.isLoading || !editState}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        id="address"
                        size="medium"
                        name="address"
                        label={"Address"}
                        value={values.address}
                        error={
                          touched.address !== null &&
                          touched.address &&
                          Boolean(errors.address)
                        }
                        helperText={touched.address && errors.address}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        alignContent: "space-between",
                        bottom: "10px",
                        right: "10px",
                        gap: "10px",
                        padding: "10px",
                      }}
                    >
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        disabled={
                          organisationMutation.isLoading || isSubmitting
                        }
                        size="small"
                        className={!editState ? classes.noneDisplay : ""}
                        loading={organisationMutation.isLoading}
                      >
                        Save Changes
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        type="reset"
                        disabled={
                          organisationMutation.isLoading || isSubmitting
                        }
                        size="small"
                        color="warning"
                        onClick={CancelChanges}
                        className={!editState ? classes.noneDisplay : ""}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </CardContent>
                )}
              </Card>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

interface InputFieldProps {
  label: string;
  editState: boolean;
  loadingState: boolean;
  id: string;
  name: string;
  value: string;
  type: string;
  handleChange: any;
  error: boolean;
  helperText: string;
  inputProps?: any;
}

const InputField = ({
  label = "",
  editState = false,
  loadingState = false,
  id = "",
  name = "",
  value = "",
  handleChange,
  type = "text",
  error = false,
  helperText = "",
  inputProps = {},
}: InputFieldProps) => {
  return (
    <TextField
      id="outlined-basic"
      name={name}
      label={label}
      variant="outlined"
      disabled={!editState || loadingState}
      value={value}
      onChange={handleChange}
      type={type}
      error={error}
      helperText={helperText}
      inputProps={inputProps}
    />
  );
};

const useStyles = makeStyles({
  noneDisplay: { display: "none" },
  // menuItem: { paddingTop: '12px', paddingBottom: '12px' , paddingLeft: '24px', paddingRight: '32px'}
  error: { color: "red" },
});

export const IndustryAutoComplete = (props: {
  value: string;
  onChange: () => {};
  setFieldValue: any;
  readOnly: boolean;
  disabled: boolean;
  error: boolean | undefined;
  helperText: string | false | undefined;
  size: "medium" | "small";
}) => {
  const handleChange = (event: React.SyntheticEvent, value: string) => {
    props.setFieldValue("industry", value);
  };

  return (
    <Autocomplete
      fullWidth
      size={props.size}
      disableClearable
      disabled={props.disabled}
      readOnly={props.readOnly}
      value={props.value}
      onChange={handleChange}
      disablePortal
      id="industry"
      options={industries}
      renderInput={(params) => (
        <TextField value={props.value} {...params} label="Industry" />
      )}
    />
  );
};
