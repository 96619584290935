import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { User, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { extractClip } from "data/mutations";
import { fetchBillingUsage, fetchOverage } from "data/queries";
import { useSnackbar } from "notistack";
import {
  JobType,
  UseOverageCreditsCard,
} from "pages/shared/use-overage-credits-card";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toTitleCase } from "utils/commons";
import { resolveIdentityId } from "utils/identity";
import { ClipConfiguration, QuoteSummary } from "../../models";

interface PurchaseWithCreditsProps {
  clipConfig: ClipConfiguration;
  quoteSummary: QuoteSummary;
  planName: string;
  setClipConfig: React.Dispatch<React.SetStateAction<ClipConfiguration>>;
  resetClipConfig: () => void;
  setSubStep: React.Dispatch<React.SetStateAction<number>>;
}

export const PurchaseWithCredits = (props: PurchaseWithCreditsProps) => {
  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { enqueueQueryFailed, enqueueMutationFailed } = useCustomSnackbars();

  const [isEnoughCredits, setIsEnoughCredits] = useState(false);

  const billingUsageQuery = useQuery(
    [QueryKeys.billingUsage, identityId],
    () => fetchBillingUsage(),
    {
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );

  const overageQuery = useQuery([QueryKeys.overage, identityId], () =>
    fetchOverage()
  );

  const submitExtractJob = useMutation(
    (clipId: string) => extractClip(clipId, "credits"),
    {
      onSuccess: (clipConfig: ClipConfiguration) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.clipsKey, identityId],
        }),
          enqueueSnackbar("Clip has been submitted for extraction.", {
            variant: "success",
          });
        props.resetClipConfig();
      },
      onError: (error: Error) => {
        enqueueMutationFailed(error.toString());
      },
    }
  );

  const isLoading = billingUsageQuery.isLoading || overageQuery.isLoading;

  const isCreditsRemaining =
    Number(billingUsageQuery.data?.allowance) +
      Number(overageQuery.data?.creditsLimit) -
      Number(billingUsageQuery.data?.creditsUsedTotal) -
      props.quoteSummary.totalCredits >
    0;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        maxHeight="calc(86vh - 64px)"
        overflow="auto"
      >
        <TextField
          fullWidth
          disabled
          label="Contact Email"
          value={(identityState as User).email}
          variant="outlined"
          size="small"
          sx={{ marginTop: 1 }}
        />
        <TextField
          fullWidth
          disabled
          label="Contact Phone"
          value=""
          variant="outlined"
          size="small"
        />
        {isLoading && <Skeleton variant="rectangular" height={100}></Skeleton>}
        {(billingUsageQuery.isSuccess || overageQuery.isSuccess) && (
          <TableContainer sx={{ overflow: "visible" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="none"
                    align="left"
                    sx={{ fontWeight: "bold" }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="right"
                    sx={{ fontWeight: "bold" }}
                  >
                    Credits
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ padding: 0 }} align="left">
                    {`${toTitleCase(props.planName)} Plan Credits`}
                  </TableCell>
                  <TableCell sx={{ padding: 0 }} align="right">
                    {props.quoteSummary.totalCredits.toLocaleString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: 0 }} align="right">
                    Credit Balance
                  </TableCell>
                  <TableCell sx={{ padding: 0 }} align="right">
                    {(
                      Number(billingUsageQuery.data?.allowance) +
                      Number(overageQuery.data?.creditsLimit) -
                      Number(billingUsageQuery.data?.creditsUsedTotal)
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: 0 }} align="right">
                    Clip Cost
                  </TableCell>
                  <TableCell sx={{ padding: 0 }} align="right">
                    {props.quoteSummary.totalCredits.toLocaleString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ padding: 0, fontWeight: "bold" }}
                    align="right"
                  >
                    Remaining Balance
                  </TableCell>
                  <TableCell
                    sx={{ padding: 0, fontWeight: "bold" }}
                    align="right"
                  >
                    {(
                      Number(billingUsageQuery.data?.allowance) +
                      Number(overageQuery.data?.creditsLimit) -
                      Number(billingUsageQuery.data?.creditsUsedTotal) -
                      props.quoteSummary.totalCredits
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {!isCreditsRemaining && (
          <UseOverageCreditsCard
            type={JobType.CLIP}
            sx={{
              gridRow: "1",
              gridColumn: "span 2",
              padding: "16px",
              boxShadow: 0,
              border: "1px solid lightgrey",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "visible",
            }}
            jobCreditCost={props.quoteSummary.totalCredits}
            isLoading={isLoading}
            setIsEnoughCredits={setIsEnoughCredits}
          />
        )}
      </Box>

      <Box alignSelf="flex-end" marginTop="auto" display="flex" paddingTop={1}>
        <Button onClick={() => props.setSubStep(0)} variant="text">
          Back
        </Button>
        {billingUsageQuery.isSuccess && (
          <LoadingButton
            loading={submitExtractJob.isLoading}
            variant="text"
            disabled={!isEnoughCredits && !isCreditsRemaining}
            onClick={() => {
              submitExtractJob.mutate(props.clipConfig.id);
            }}
          >
            Purchase
          </LoadingButton>
        )}
      </Box>
    </>
  );
};
