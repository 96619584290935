import { ErrorBoundary } from "@sentry/react";
import { ApiKeysPage, CreateKey } from "components/api-keys";
import { EditApiKey } from "components/api-keys/edit-api-key";
import { Dashboard } from "components/dashboard/dashboard";
import { BillingDetails as OrgBillingDetails } from "components/settings/organization/billing-details/billing-details";
import { GeneralSettings } from "components/settings/organization/general-settings/general-settings";
import { OrganisationMembers } from "components/settings/organization/members/members";
import { Overage as OrgOnDemand } from "components/settings/organization/overage/overage";
import { OrganisationManageSubscription } from "components/settings/organization/subscription/organisation-manage-subscription";
import { Subscription as OrgSubscription } from "components/settings/organization/subscription/subscription";
import { LegalPage } from "components/settings/user/legal/legal";
import { UserInvitations } from "components/settings/user/pending-invitations/user-invitations";
import { UserSecurity } from "components/settings/user/security/user-security";
import { ErrorFallback } from "components/shared/error-fallback";
import { TestError } from "components/test";
import { Usage } from "components/usage/usage-page";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchDemos } from "data/queries";
import { BatchPage } from "pages/geoscape-batch/batch-page";
import { CreateBatchPage } from "pages/geoscape-batch/create-batch-steps/create-batch-page";
import { CatalogueEntry } from "pages/geoscape-data/catalogue/catalogue-entry/catalouge-entry";
import { CataloguePage } from "pages/geoscape-data/catalogue/catalogue-page";
import { CustomDownloadPage } from "pages/geoscape-data/custom-download/custom-page";
import { DataPage } from "pages/geoscape-data/dashboard/dashboard-page";
import { DownloadPage } from "pages/geoscape-data/download/download-page";
import { ExplorerPage } from "pages/geoscape-data/explorer/explorer-page";
import { TailorPage } from "pages/geoscape-data/tailor/tailor-page";
import {
  DataDemos,
  DemoItem,
  DemosPage,
} from "pages/geoscape-demos/demo-page/demos-page";
import { DocsPage } from "pages/geoscape-docs/docs-page";
import { HubPage } from "pages/geoscape-hub/dashboard/dashboard-page";
import { Suspense, lazy } from "react";
import { useQuery } from "react-query";
import { Redirect, Route, Switch, useLocation } from "react-router";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { DemoRoutes } from "./demo-routes";

const OrganisationSearch = lazy(
  () => import("pages/geoscape-admin/organisation/organisation-search")
);
const AdminUsers = lazy(
  () => import("pages/geoscape-admin/users/search/admin-users-search")
);
const AdminDemos = lazy(
  () => import("pages/geoscape-admin/demo-management/admin-demos")
);
const AdminEditDemo = lazy(
  () => import("pages/geoscape-admin/demo-management/admin-edit-demo")
);
const AdminUserDetails = lazy(
  () => import("pages/geoscape-admin/users/user-details/admin-user-details")
);
const AdminOrganisationTabs = lazy(
  () => import("pages/geoscape-admin/organisation/tabs/organisation-tabs")
);
const AdminRoutes = lazy(() => import("routes/admin-routes"));

const AdminDashboardPage = lazy(
  () => import("pages/geoscape-admin/dashboard/dashboard-page")
);

const MangeReleasePage = lazy(
  () => import("pages/geoscape-admin/manage-release/manage-release-page")
);
const NewRelease = lazy(
  () => import("pages/geoscape-admin/manage-release/new-release/new-release")
);
const ViewAllDatasets = lazy(
  () => import("pages/geoscape-admin/manage-release/view-all/view-all-datasets")
);
const AdminDatasets = lazy(
  () => import("pages/geoscape-admin/datasets/admin-datasets-page")
);
const AdminClipReporting = lazy(
  () => import("pages/geoscape-admin/reporting/clip/admin-clip-reporting")
);
const AdminCustomDatasetsSearch = lazy(
  () =>
    import("pages/geoscape-admin/custom-datasets/admin-custom-datasets-search")
);

export const RouteSwitch = () => {
  const location = useLocation();
  const errorKey = location.pathname;

  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState);

  const demosQuery = useQuery([QueryKeys.demosKey, identityId], () =>
    fetchDemos()
  );

  const getDemoItem = (demoId: string): DemoItem | undefined => {
    return DataDemos.find((item) => item.id === demoId);
  };

  return (
    <ErrorBoundary key={errorKey} fallback={<ErrorFallback />}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={Routes.hub} component={HubPage} />

          {/* DEVELOPER */}
          <Route exact path={Routes.developerDashboard} component={Dashboard} />
          <Route
            exact
            path={Routes.developerApiKeysCreateKey}
            component={CreateKey}
          />
          <Route exact path={Routes.developerApiKeys} component={ApiKeysPage} />
          <Route exact path={Routes.developerUsage} component={Usage} />
          <Route
            exact
            path={Routes.developerApiKeysEditKey}
            component={EditApiKey}
          />

          <Route exact path={Routes.docsPage} component={DocsPage} />
          {/* DATA */}

          <Route exact path={Routes.dataDashboard} component={DataPage} />
          <Route exact path={Routes.dataCatalogue} component={CataloguePage} />

          <Route exact path={Routes.demosPage} component={DemosPage} />
          <Route
            exact
            path={Routes.dataCatalogueEntry}
            component={CatalogueEntry}
          />
          <Route exact path={Routes.dataExplorer} component={ExplorerPage} />

          {/** Demos */}
          {DataDemos.map((item: DemoItem) => (
            <DemoRoutes
              exact
              path={item.path}
              component={getDemoItem(item.id)?.component}
            />
          ))}

          <Route path={Routes.dataExplorerClip} component={ExplorerPage} />

          <Route
            exact
            path={Routes.dataCustomDownload}
            component={CustomDownloadPage}
          />

          <Route exact path={Routes.dataTailor} component={TailorPage} />
          <Route exact path={Routes.dataDownload} component={DownloadPage} />

          {/* Batches */}
          <Route exact path={Routes.batchBatches} component={BatchPage} />

          <Route
            exact
            path={Routes.batchCreateBatch}
            component={CreateBatchPage}
          />
          <Route
            exact
            path={Routes.userPendingInvitations}
            component={UserInvitations}
          />
          <Route exact path={Routes.userSecurity} component={UserSecurity} />
          <Route exact path={Routes.legal} component={LegalPage} />
          {/* Secret endpoint for testing error handling */}
          <Route exact path={Routes.testError} component={TestError} />

          {/*  Org Routes */}
          <OrgRoutes path={Routes.orgGeneralSettings}>
            <GeneralSettings />
          </OrgRoutes>
          <OrgRoutes path={Routes.orgMembers}>
            <OrganisationMembers />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgSubscription}>
            <OrgSubscription />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgOverage}>
            <OrgOnDemand />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgBillingDetails}>
            <OrgBillingDetails />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgManageSubscription}>
            <OrganisationManageSubscription />
          </OrgRoutes>

          {/* Admin Routes */}
          <AdminRoutes exact path={Routes.adminDashboard}>
            <AdminDashboardPage />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminOrganisations}>
            <OrganisationSearch />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminDemoManagement}>
            <AdminDemos />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminEditDemo}>
            <AdminEditDemo />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminUsers}>
            <AdminUsers />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminUserDetails}>
            <AdminUserDetails />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminReleases}>
            <MangeReleasePage />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminCreateRelease}>
            <NewRelease />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminEditRelease}>
            <Route
              exact
              path={Routes.adminEditRelease}
              component={NewRelease}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminReleaseDatasets}>
            <Route
              exact
              path={Routes.adminReleaseDatasets}
              component={ViewAllDatasets}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminDatasets}>
            <AdminDatasets />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminClipReporting}>
            <AdminClipReporting />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminViewCustomDatasets}>
            <AdminCustomDatasetsSearch />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminOrganisationDetails}>
            {/* <AdminAccountDetails/>  This approach does not recive the path params */}
            <Route
              path={Routes.adminOrganisationDetails}
              component={AdminOrganisationTabs}
            />
          </AdminRoutes>

          <Route path="*">
            <Redirect to={Routes.hub} />
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

const OrgRoutes = ({ children, ...rest }: any) => {
  const [identity] = useIdentity();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        getActiveOrganisation(identity) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
