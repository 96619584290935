import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { DataCatalogueEntry, DatasetDefinitions } from "data/models";
import { fetchCatalogueList, fetchDefinitions } from "data/queries";
import _ from "lodash";
import { useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { CatalogueCard } from "./catalogue-card";

export const CataloguePage = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const [identityState] = useIdentity();

  const identityId = resolveIdentityId(identityState);
  const [query, setQuery] = useState("");

  const catalogueListQuery = useQuery(
    [QueryKeys.catalogueListKey, identityId, query],
    () => fetchCatalogueList(query)
  );

  const datasetDefinitions: UseQueryResult<DatasetDefinitions> = useQuery(
    [QueryKeys.definitionsKey],
    () => fetchDefinitions()
  );

  const isLoading: boolean =
    catalogueListQuery.isLoading || datasetDefinitions.isLoading;
  const isError: boolean =
    catalogueListQuery.isError || datasetDefinitions.isError;
  const isSuccess: boolean =
    catalogueListQuery.isSuccess && datasetDefinitions.isSuccess;

  const skeletonArray: number[] = _.range(1, 18);
  return (
    <Stack direction="column" gap={2}>
      <Card>
        <CardHeader
          sx={{ alignSelf: "flex-start" }}
          title="Data Catalogue"
        ></CardHeader>
        <CardContent>
          <TextField
            id="query"
            name="query"
            label="Search"
            type="text"
            value={query}
            onChange={(e: any) => {
              setQuery(e.target.value);
            }}
            disabled={isError}
            helperText={
              "Search for keywords to narrow the list of data products"
            }
            variant={"outlined"}
            fullWidth
          />
        </CardContent>
      </Card>
      <Grid container spacing={2}>
        <ReactQueryErrorWrapper
          queries={[catalogueListQuery, datasetDefinitions]}
          mutations={[]}
        />

        {isLoading &&
          skeletonArray.map((i) => {
            return (
              <Grid item md={12} lg={6} key={i}>
                <Skeleton variant="rectangular" height="250px" />
              </Grid>
            );
          })}

        {isSuccess &&
          catalogueListQuery.data
            .sort((a: DataCatalogueEntry, b: DataCatalogueEntry) =>
              a.id.localeCompare(b.id)
            )
            .map((catalogueItem: DataCatalogueEntry, index: number) => {
              return (
                <Grid item md={12} lg={6} key={`${catalogueItem.id}-${index}`}>
                  <CatalogueCard
                    productId={catalogueItem.id}
                    displayProduct={catalogueItem.displayProduct}
                    totalFeatureCount={catalogueItem.metadata.totalFeatureCount}
                    shortDescription={catalogueItem.shortDescription}
                    updatedDate={
                      // Replace GEOSCAPE PLANNING INSIGHTS with GEOSCAPE PLANNING INSIGHTS - BASE
                      datasetDefinitions.data?.metadata[
                        catalogueItem.id === "GEOSCAPE PLANNING INSIGHTS"
                          ? "GEOSCAPE PLANNING INSIGHTS - BASE"
                          : catalogueItem.id
                      ].latestRelease
                    }
                    image={`data:image/png;base64,${
                      isDark
                        ? catalogueItem.imageDark
                        : catalogueItem.imageLight
                    }`}
                    dataType={catalogueItem.metadata.dataType}
                  ></CatalogueCard>
                </Grid>
              );
            })}
      </Grid>
    </Stack>
  );
};

export const viewDatasetInExplorer = (dataset: string, history: any) => {
  // Substitute GEOSCAPE PLANNING INSIGHTS for GEOSCAPE PLANNING INSIGHTS - BASE
  const datasetId =
    dataset == "GEOSCAPE PLANNING INSIGHTS"
      ? "GEOSCAPE PLANNING INSIGHTS - BASE"
      : dataset;

  sessionStorage.setItem("catalogueToExplorer", datasetId);
  history.push(Routes.dataExplorer);
};
