import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
  AdminOrganisationDetail,
  AdminUserNote,
} from "pages/geoscape-admin/organisation/organisation-details/utils";
import {
  ClipConfiguration,
  ClipInvoice,
  QuoteSummary,
} from "pages/geoscape-data/explorer/clip/models";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import {
  Address,
  CRS,
  Suggest,
} from "pages/geoscape-demos/demos/predictive-address/models";
import { SuggestLocality } from "pages/geoscape-demos/demos/predictive-suburb/models";
import { makeApiRequestPostToUrl, makeApiRequestToUrl } from "utils/api-client";
import {
  AdminAccountDetails,
  AdminSearchAccount,
  AdminSearchOrganisation,
  ApiKey,
  BillingUsage,
  CustomDatasetDefinitions,
  CustomDatasetFilterType,
  CustomDatasetList,
  Dataset,
  DatasetDefinitions,
  DatasetFiltersType,
  DatasetList,
  DemoConfiguration,
  Organisation,
  Overage,
  PaymentMethod,
  PortalPlan,
  Release,
  Subscription,
  UserMembership,
  UserStatus,
} from "./models";

interface ReactQueryQueryError {
  message: string;
  stack: string;
}

async function fetchOrganisation(): Promise<Organisation> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/v2/organisation",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch organisation: ${err}`);
  }
}

async function fetchPaymentMethod(): Promise<PaymentMethod | null> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/paymentMethod",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    const paymentMethods: PaymentMethod[] = responseData.filter(
      (paymentMethod: PaymentMethod) => paymentMethod.active
    );
    if (paymentMethods.length !== 0) {
      return paymentMethods[0];
    } else {
      return null;
    }
  } catch (err) {
    throw new Error(`Unable to fetch payment method: ${err}`);
  }
}

async function fetchCustomDatasets(
  queryFields: CustomDatasetFilterType,
  limit: number = 100,
  offset: number = 0
): Promise<CustomDatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    queryFields_update.product = queryFields_update.name
      ? queryFields_update.name.map((name: string) => name.toUpperCase())
      : "";
    delete queryFields_update.name;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", "100");
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom?${searchParams.toString()}`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all custom datasets: ${err}`);
  }
}

async function fetchCustomDatasetsAdmin(
  queryFields: CustomDatasetFilterType,
  apigeeDeveloperId: string,
  limit: number = 100,
  offset: number = 0
): Promise<CustomDatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    queryFields_update.product = queryFields_update.name
      ? queryFields_update.name.map((name: string) => name.toUpperCase())
      : "";
    delete queryFields_update.name;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", `${limit}`);
    searchParams.set("offset", `${offset}`);

    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/developer/${apigeeDeveloperId}?${searchParams.toString()}`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch all custom datasets as admin for account '${apigeeDeveloperId}' : ${err}`
    );
  }
}

async function fetchDatasets(
  queryFields: DatasetFiltersType,
  limit: number = 100,
  offset: number = 0
): Promise<DatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", `${limit}`);
    searchParams.set("offset", `${offset}`);
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets?${searchParams.toString()}`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all datasets: ${err}`);
  }
}

async function fetchAdminDatasets(
  queryFields: DatasetFiltersType,
  limit: number = 100,
  offset: number = 0
): Promise<DatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", `${limit}`);
    searchParams.set("offset", `${offset}`);
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/search?${searchParams.toString()}`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all datasets as admin: ${err}`);
  }
}

async function fetchAdminDatasetsLink(datasetId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/download/${datasetId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(
      `Unable to download dataset as admin for dataset '${datasetId}' : ${err}`
    );
  }
}

async function fetchDatasetsLink(datasetId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/${datasetId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(`Unable to download dataset '${datasetId}' : ${err}`);
  }
}

async function fetchCustomDatasetsLink(datasetId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom/${datasetId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(
      `Unable to download custom dataset '${datasetId}' : ${err}`
    );
  }
}

async function fetchAdminCustomDatasetsLink(datasetId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/${datasetId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(
      `Unable to download custom dataset '${datasetId}' : ${err}`
    );
  }
}

async function fetchAdminCustomDatasetsSearch(
  queryFields: CustomDatasetFilterType,
  limit: number = 100,
  offset: number = 0
): Promise<CustomDatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    queryFields_update.product = queryFields_update.name
      ? queryFields_update.name.map((name: string) => name.toUpperCase())
      : "";
    delete queryFields_update.name;

    let searchParams = new URLSearchParams();

    searchParams.set("limit", `${limit}`);
    searchParams.set("offset", `${offset}`);

    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/search?${searchParams.toString()}`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all custom datasets as admin : ${err}`);
  }
}

async function fetchSubscription(): Promise<Subscription> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/subscriptions",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch subscription: ${err}`);
  }
}

async function fetchOverage(): Promise<Overage> {
  let overage: Overage = {
    enabled: false,
    dollarsSpent: 0,
    dollarsLimit: 0,
    creditsSpent: 0,
    creditsLimit: 0,
    overageRate: 0,
  };
  // Get the overage limit in dollars
  try {
    let overageResponse = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/overage",
      "GET"
    );
    const overageResponseData = await overageResponse.json();

    if (overageResponse.status !== 200) {
      throw new Error(overageResponseData.messages.join(". "));
    }

    overage.enabled = overageResponseData.enabled;
    overage.dollarsLimit = overageResponseData.limitDollars;
    overage.creditsLimit = overageResponseData.limitCredits;
    overage.overageRate = overageResponseData.overageRate;
  } catch (err) {
    throw new Error(`Unable to fetch overage (overage): ${err}`);
  }

  try {
    let billingUsageResponse = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/billingUsage",
      "GET"
    );
    const billingUsageResponseData = await billingUsageResponse.json();

    if (billingUsageResponse.status !== 200) {
      throw new Error(billingUsageResponseData.messages.join(". "));
    }

    overage.creditsSpent = billingUsageResponseData.overageCredits;
    overage.dollarsSpent = billingUsageResponseData.overageDollars;
  } catch (err) {
    throw new Error(`Unable to fetch overage (billing usage): ${err}`);
  }
  return overage;
}

async function fetchUserMemberships(): Promise<UserMembership[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/v2/user/membership",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch user invitations: ${err}`);
  }
}

async function fetchLatestVersion() {
  try {
    const response = await fetch("/version.json", {
      cache: "no-store",
    });
    const data = await response.json();
    const latestVersion = data.version;
    return latestVersion;
  } catch (error) {
    console.error("Error checking for updates:", error);
  }
}

async function fetchUserInvitations(): Promise<UserMembership[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/v2/user/membership?byEmail=true",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch user invitations: ${err}`);
  }
}

async function fetchBillingUsage(): Promise<BillingUsage> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/billingUsage",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch billing usage: ${err}`);
  }
}

async function fetchUsage(
  startTime: number,
  endTime: number,
  overrideIntervalTime?: string
) {
  try {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    // Get the timezone string from the user's device
    const userTimezone = dayjs.tz.guess();

    // Construct the query parameters
    let queryParams = `offset=${userTimezone}&startTime=${startTime}&endTime=${endTime}`;

    if (overrideIntervalTime !== undefined) {
      queryParams += `&overrideIntervalTime=${overrideIntervalTime}`;
    }

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/usage?${queryParams}`,
      "GET"
    );

    const responseData = await response.json(); // add type: Usage

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch usage data: ${err}`);
  }
}

async function fetchApiKeys() {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/apiKeys`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch API keys: ${err}`);
  }
}

async function fetchDataReleases(): Promise<Release[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/release`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch data releases: ${err}`);
  }
}

async function fetchReleaseDatasets(releaseId: string): Promise<Dataset[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/release/${releaseId}/datasets`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch data release '${releaseId}' : ${err}`);
  }
}

async function fetchDefinitions(): Promise<DatasetDefinitions> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/definitions`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch dataset definitions: ${err}`);
  }
}

async function fetchCustomDefinitions(): Promise<CustomDatasetDefinitions> {
  let products;

  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom/definitions`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch custom dataset definitions: ${err}`);
  }
}

async function fetchAdminCustomDefinitions(): Promise<CustomDatasetDefinitions> {
  let products;

  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/definitions`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch admin custom dataset definitions: ${err}`);
  }
}

async function fetchApiProducts() {
  let apiProducts;

  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/products`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch API products data: ${err}`);
  }
}

async function fetchOrganisationInvitations(organisationId: string) {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/v2/organisation/membership?orgId=${
        organisationId ? encodeURIComponent(organisationId) : ""
      }`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch organisation invitations for organisation '${organisationId}' : ${err}`
    );
  }
}

// Query for fetching the Users in the admin portal search
async function fetchOrganisationsAdminSearch(
  query: string
): Promise<AdminSearchOrganisation[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/search?query=${encodeURIComponent(query)}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch users with query '${query}' : ${err}`);
  }
}

async function fetchAdminUsage(
  organisationId: string,
  startTime: number,
  endTime: number
) {
  try {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    // Get the timezone string from the user's device
    const userTimezone = dayjs.tz.guess();
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/usage?organisationId=${organisationId}&offset=${userTimezone}&startTime=${startTime}&endTime=${endTime}`,
      "GET"
    );

    const responseData = await response.json(); // add type: Usage

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch usage data: ${err}`);
  }
}

// Query for fetching the Cognito Users in the admin portal search
async function fetchAdminUsersSearch(
  query: string,
  searchTerm: string
): Promise<AdminSearchAccount[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/accounts/search?query=${encodeURIComponent(
        query
      )}&term=${searchTerm}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch users with query '${query}' and term '${searchTerm}': ${err}`
    );
  }
}

// Query for fetching the Users Details in the admin portal search
async function fetchAdminUserDetails(
  accountId: string
): Promise<AdminAccountDetails> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/accounts/${accountId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch user details as admin for account '${accountId}' : ${err}`
    );
  }
}

// Query for fetching the Users Details in the admin portal search
async function fetchOrganisationDetailsAdmin(
  organisationId: string
): Promise<AdminOrganisationDetail> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/${organisationId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch user details as admin for organisation '${organisationId}' : ${err}`
    );
  }
}

async function checkAdminNetworkAccessibility(): Promise<boolean> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/admin/network",
      "GET"
    );

    return response.status === 200;
  } catch (err) {
    return false;
  }
}

async function checkDatasetsAccessibility(): Promise<boolean> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/datasets/beta",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      return false;
    }

    return responseData;
  } catch (err) {
    return false;
  }
}

async function fetchAdminOrgMembers(orgId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/members?organisationId=${encodeURIComponent(orgId)}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch organisation members as admin for organisation '${orgId}' : ${err}`
    );
  }
}

async function fetchAdminUserMemberships(
  userId: string
): Promise<UserMembership[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/user/membership?userId=${encodeURIComponent(
        userId
      )}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch user memberships as admin for the user '${userId}' : ${err}`
    );
  }
}

async function fetchAvailableSubscriptions(): Promise<PortalPlan[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/subscriptions/all`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all available subscriptions: ${err}`);
  }
}

async function fetchAdminUserApiKeys(
  organisationId: string
): Promise<ApiKey[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/apiKeys?organisationId=${organisationId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch api keys as admin for user '${organisationId}' : ${err}`
    );
  }
}

async function fetchAdminUserApiKey(
  apiKeyName: string,
  organisationId: string
): Promise<ApiKey> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/apiKeys/${apiKeyName}?organisationId=${organisationId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch api key for user as admin for user '${organisationId}' and api key '${apiKeyName}': ${err}`
    );
  }
}

async function fetchCustomDatasetsDefinitions(
  developerId: string
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom/definitions?developerId=${developerId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch custom dataset definitions for user '${developerId}' : ${err}`
    );
  }
}

async function fetchCatalogueEntry(productId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/dataCatalogues/${productId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch data catalogue entry for product '${productId}' : ${err}`
    );
  }
}

async function fetchCatalogueList(query: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/dataCatalogues?query=${query}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch all data catalogue products for query '${query}' : ${err}`
    );
  }
}

interface ContractInfo {
  contract_start: string;
  contract_end: string;
  product_name: string;
  regions: string[];
}
interface UserLicences {
  dataset_products: ContractInfo[];
}

async function fetchUserLicences(): Promise<UserLicences> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/licences`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch user licences: ${err}`);
  }
}

async function fetchIntercomIdentityHash(): Promise<string> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/intercom/user_hash`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch intercom user hash: ${err}`);
  }
}

async function fetchAuthJWT() {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}

async function fetchMapsIndex(authorisation: string): Promise<TileJSON[]> {
  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_API_BASE_URL
      }/v1/explorer/index.json?key=${authorisation}`
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch maps index: ${err}`);
  }
}

async function fetchMapsLayerJson(
  authorisation: string,
  layer: string
): Promise<TileJSON> {
  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_API_BASE_URL
      }/v1/explorer/geoscape_v1/${layer}.json?key=${authorisation}`
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch maps ${layer} layer json: ${err}`);
  }
}

async function fetchBatches(): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/config`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all batch jobs: ${err}`);
  }
}

// Fetch a single batch config
async function fetchBatchConfig(batchId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/config/${batchId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch batch job '${batchId}' : ${err}`);
  }
}

async function fetchBatchesDefinitions(): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/definitions`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch batch definitions: ${err}`);
  }
}

async function fetchBatchesAdditionalProperties(): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/additionalProperties`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch batch additional properties: ${err}`);
  }
}

async function fetchAddressById(
  addressId: string,
  crs?: CRS
): Promise<Address> {
  try {
    const headers = new Headers();
    if (crs) headers.append("Accept-Crs", crs);

    const response = await fetch(
      `https://api.psma.com.au/v1/predictive-demo/address/${addressId}`,
      {
        headers: headers,
      }
    );
    const basictransaction = response.headers.get("x-attr-basictransaction");
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return { ...responseData, basictransaction: Number(basictransaction) };
  } catch (err) {
    throw new Error(`Unable to fetch address by id '${addressId}' : ${err}`);
  }
}

async function fetchPredictive(
  query: string,
  dataset: string,
  excludeAliases: boolean,
  addressType: string,
  stateTerritory: string,
  maxNumberOfResults: number
): Promise<Suggest> {
  try {
    const response = await fetch(
      `https://api.psma.com.au/v1/predictive-demo/address?` +
        new URLSearchParams({
          query: query,
          dataset: dataset,
          excludeAliases: excludeAliases.toString(),
          addressType: addressType,
          stateTerritory: stateTerritory,
          maxNumberOfResults: maxNumberOfResults.toString(),
        }).toString()
    );
    const responseData = await response.json();
    const basictransaction = response.headers.get("x-attr-basictransaction");

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return { ...responseData, basictransaction: Number(basictransaction) };
  } catch (err) {
    throw new Error(`Unable to fetch predictive addresses '${query}' : ${err}`);
  }
}

async function fetchPredictiveLocality(
  query: string,
  postcode: string,
  stateTerritory: string,
  maxNumberOfResults: number
): Promise<SuggestLocality> {
  try {
    const response = await fetch(
      `https://api.psma.com.au/v1/predictive-demo/locality?` +
        new URLSearchParams({
          query: query,
          postcode: postcode,
          stateTerritory: stateTerritory,
          maxNumberOfResults: maxNumberOfResults.toString(),
        }).toString()
    );

    const responseData = await response.json();
    const basictransaction = response.headers.get("x-attr-basictransaction");

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return { ...responseData, basictransaction: Number(basictransaction) };
  } catch (err) {
    throw new Error(
      `Unable to fetch predictive localities '${query}' : ${err}`
    );
  }
}

async function fetchAdminBounds(
  identifier: string,
  layers: string,
  excludeGeometry: boolean,
  crs?: CRS
): Promise<SuggestLocality> {
  try {
    const headers = new Headers();
    if (crs) headers.append("Accept-Crs", crs);

    const response = await fetch(
      `https://api.psma.com.au/v1/admin-bounds-demo/findByIdentifier?` +
        new URLSearchParams({
          identifier: identifier,
          layers: layers,
          excludeGeometry: excludeGeometry.toString(),
        }).toString(),
      {
        headers: headers,
      }
    );

    const responseData = await response.json();
    const basictransaction = response.headers.get("x-attr-basictransaction");

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return { ...responseData, basictransaction: Number(basictransaction) };
  } catch (err) {
    throw new Error(
      `Unable to fetch admin bounds for  identifier '${identifier}' : ${err}`
    );
  }
}

async function fetchClips(): Promise<ClipConfiguration[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/config`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all clips: ${err}`);
  }
}

async function fetchAdminClips(): Promise<ClipConfiguration[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/clip/config`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all clips for admin: ${err}`);
  }
}

// Fetch a single clip config
async function fetchClipConfig(clipId: string): Promise<ClipConfiguration> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/config/${clipId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch clip config '${clipId}' : ${err}`);
  }
}

const fetchBatchSample = async (
  batchId: string,
  body: any,
  jobType: string
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/sample?jobType=${jobType}`,
      "POST",
      body
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch sample results for batch '${batchId}' : ${err}`
    );
  }
};
// Fetch a single clip summary
async function fetchClipSummary(clipId: string): Promise<QuoteSummary> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/summary/${clipId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch clip summary for '${clipId}' : ${err}`);
  }
}

async function fetchAdminClipSummary(clipId: string): Promise<QuoteSummary> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/clip/summary/${clipId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch clip summary for admin '${clipId}' : ${err}`
    );
  }
}

async function fetchAdminClipInvoice(clipId: string): Promise<ClipInvoice> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/clip/${clipId}/invoice`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch clip invoice for admin '${clipId}' : ${err}`
    );
  }
}

async function fetchLandParcelsProperties(addressId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/landParcels/properties/findByIdentifier?addressId=${addressId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch land parcels properties for address id ${addressId}: ${err}`
    );
  }
}

async function fetchLandParcelsCadastresId(
  cadastrePid: string
): Promise<GeoJSON.FeatureCollection> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/landParcels/cadastres/${cadastrePid}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch land parcels cadastres for cadastre pid ${cadastrePid}: ${err}`
    );
  }
}

async function fetchLandParcelsCadastres(addressId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/landParcels/cadastres/findByIdentifier?addressId=${addressId}&additionalProperties=planningBundle`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch land parcels cadastres for address id ${addressId}: ${err}`
    );
  }
}

async function fetchBuildingsByAddressId(addressId: string): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/buildings?addressId=${addressId}&include=all`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch buildings for address id ${addressId}: ${err}`
    );
  }
}

async function fetchBuildingsByIdentifier(
  idKey: string,
  idValue: string,
  additionalProperties: string[]
): Promise<GeoJSON.FeatureCollection> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/buildings/findByIdentifier?${idKey}=${idValue}&additionalProperties=${additionalProperties.join(
        ","
      )}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch buildings for ${idKey} ${idValue}: ${err}`
    );
  }
}

async function fetchAdminUserNote(accountId: string): Promise<AdminUserNote> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/note/${accountId}`,
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch admin user note for the account: ${accountId} : ${err}`
    );
  }
}

async function fetchUserStatus(): Promise<UserStatus> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/user/status",
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch user status. ${err}`);
  }
}

async function fetchDemos(): Promise<DemoConfiguration[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/demos",
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch demos. ${err}`);
  }
}

async function fetchAdminDemos(): Promise<DemoConfiguration[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/admin/demos",
      "GET"
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch admin demos. ${err}`);
  }
}

export {
  checkAdminNetworkAccessibility,
  checkDatasetsAccessibility,
  fetchAddressById,
  fetchAdminBounds,
  fetchAdminClipInvoice,
  fetchAdminClips,
  fetchAdminClipSummary,
  fetchAdminCustomDatasetsLink,
  fetchAdminCustomDatasetsSearch,
  fetchAdminCustomDefinitions,
  fetchAdminDatasets,
  fetchAdminDatasetsLink,
  fetchAdminDemos,
  fetchAdminOrgMembers,
  fetchAdminUsage,
  fetchAdminUserApiKey,
  fetchAdminUserApiKeys,
  fetchAdminUserDetails,
  fetchAdminUserMemberships,
  fetchAdminUserNote,
  fetchAdminUsersSearch,
  fetchApiKeys,
  fetchApiProducts,
  fetchAuthJWT,
  fetchAvailableSubscriptions,
  fetchBatchConfig,
  fetchBatches,
  fetchBatchesAdditionalProperties,
  fetchBatchesDefinitions,
  fetchBatchSample,
  fetchBillingUsage,
  fetchBuildingsByAddressId,
  fetchBuildingsByIdentifier,
  fetchCatalogueEntry,
  fetchCatalogueList,
  fetchClipConfig,
  fetchClips,
  fetchClipSummary,
  fetchCustomDatasets,
  fetchCustomDatasetsAdmin,
  fetchCustomDatasetsDefinitions,
  fetchCustomDatasetsLink,
  fetchCustomDefinitions,
  fetchDataReleases,
  fetchDatasets,
  fetchDatasetsLink,
  fetchDefinitions,
  fetchDemos,
  fetchIntercomIdentityHash,
  fetchLandParcelsCadastres,
  fetchLandParcelsCadastresId,
  fetchLandParcelsProperties,
  fetchLatestVersion,
  fetchMapsIndex,
  fetchMapsLayerJson,
  fetchOrganisation,
  fetchOrganisationDetailsAdmin,
  fetchOrganisationInvitations,
  fetchOrganisationsAdminSearch,
  fetchOverage,
  fetchPaymentMethod,
  fetchPredictive,
  fetchPredictiveLocality,
  fetchReleaseDatasets,
  fetchSubscription,
  fetchUsage,
  fetchUserInvitations,
  fetchUserLicences,
  fetchUserMemberships,
  fetchUserStatus,
};
export type { ReactQueryQueryError };
