import { Box } from "@mui/material";
import { SubscriptionCards } from "components/billing/subscription-cards";
import { UsageGraph } from "components/usage/components/usage-graph/ UsageGraph";
import { UsageSkeleton } from "components/usage/components/usage-graph/UsageSkeleton";
import { useFetchUsageGraph } from "components/usage/fetch-hooks";
import { useIdentity } from "contexts/identity-context";
import dayjs from "dayjs";
import { useState } from "react";

export const Dashboard = () => {
  const [startTime, setStartTime] = useState(
    dayjs().subtract(1, "week").unix() * 1000
  );
  const [endTime, setEndTime] = useState(dayjs().unix() * 1000);

  const [identityState] = useIdentity();

  const hourlyIntervalTime = "1h";
  const usageQuery = useFetchUsageGraph(
    identityState,
    startTime,
    endTime,
    true,
    hourlyIntervalTime
  );

  const isLoading = usageQuery.isLoading;

  const isError = usageQuery.isError;

  const isSuccess = usageQuery.isSuccess;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "700px",
        position: "relative",
      }}
    >
      <SubscriptionCards />

      {/* <UsageGraph startTime={startTime} endTime={endTime} /> */}
      <div>
        <UsageSkeleton isLoading={isLoading} />
        {isSuccess && (
          <UsageGraph
            startTime={startTime}
            endTime={endTime}
            isLoading={isLoading}
            isError={isError}
            isSuccess={isSuccess}
            usageQuery={usageQuery}
            hideLegend={true}
          />
        )}
      </div>
    </Box>
  );
};
