import { Alert, InputAdornment, Skeleton, TextField } from "@mui/material";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { BillingInterval, PortalPlan } from "data/models";
import { fetchOverage, fetchSubscription } from "data/queries";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import {
  PlanDetails,
  calculateOverageCredits,
  roundDownOverageBudget,
} from "utils/calculations";
import { resolveIdentityId } from "utils/identity";

interface OverageInputProps {
  selectedAvailableSubscriptionName: string;
  selectedSubscriptionInterval: BillingInterval;
  roundedOverageLimit: number;
  setRoundedOverageLimit: any;
  setSubscriptionSummary?: any;
  setFieldValue: any;
  value: any;
  isDisabled?: boolean;
  defaultValue?: any;
}

const OverageInput: FC<OverageInputProps> = (props: OverageInputProps) => {
  const [identityState] = useIdentity();

  const identityId = resolveIdentityId(identityState);

  const [newOverageBudget, setNewOverageBudget] = useState(0);

  const overageQuery = useQuery(
    [QueryKeys.overage, identityId],
    () => fetchOverage(),
    {
      onSuccess: (overage) => {
        if (newOverageBudget === undefined) {
          setNewOverageBudget(overage.dollarsLimit);
        }
      },
    }
  );

  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription()
  );

  const planDetails: PlanDetails = {
    overageRate: 1,
    planCredits: 1,
  };

  if (subscriptionQuery.isSuccess) {
    const selectedAvailableSubscription =
      subscriptionQuery.data.availablePlans.filter(
        (availablePlan: PortalPlan) =>
          availablePlan.name === props.selectedAvailableSubscriptionName &&
          availablePlan.interval === props.selectedSubscriptionInterval
      )[0];
    planDetails.overageRate = selectedAvailableSubscription.overageRate
      ? selectedAvailableSubscription.overageRate
      : 1;
    planDetails.planCredits = selectedAvailableSubscription.creditAmount;
  }

  const isLoading = overageQuery.isLoading || subscriptionQuery.isLoading;
  const isSuccess = overageQuery.isSuccess && subscriptionQuery.isSuccess;

  return (
    <>
      <ReactQueryErrorWrapper
        queries={[overageQuery, subscriptionQuery]}
        mutations={[]}
      />

      {isLoading && <Skeleton variant="rectangular" height={24} />}

      {isSuccess && (
        <>
          <TextField
            fullWidth
            disabled={props.isDisabled}
            margin="normal"
            variant="outlined"
            label="Budget"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputProps: { min: 0, max: 10000 },
            }}
            helperText={
              props.value === 0
                ? ""
                : `This is equivalent to ${calculateOverageCredits(
                    props.value,
                    planDetails
                  ).toLocaleString()} credits above your ${
                    props.selectedAvailableSubscriptionName
                  } plan's ${planDetails.planCredits.toLocaleString()} monthly limit`
            }
            defaultValue={props.defaultValue}
            value={props.value}
            onChange={(event) => {
              const parseNewOverageLimit: number = Math.min(
                Number(event.target.value),
                10000
              );
              const roundedOverageLimit: number = roundDownOverageBudget(
                parseNewOverageLimit,
                planDetails
              );

              props.setFieldValue("overageLimitDollars", parseNewOverageLimit);
              props.setRoundedOverageLimit(roundedOverageLimit);
              if (props.setSubscriptionSummary) {
                props.setSubscriptionSummary((prevState: any) => {
                  return {
                    ...prevState,
                    newSubscriptionOverageLimitDollars: roundedOverageLimit,
                  };
                });
              }
            }}
          />

          {props.value % planDetails.overageRate !== 0 && (
            <Alert severity="info" sx={{ marginBottom: "16px" }}>
              Budget will automatically be rounded down to the nearest $
              {planDetails.overageRate} increment ($
              {roundDownOverageBudget(props.value, planDetails)}) when saved.
            </Alert>
          )}

          {props.roundedOverageLimit === 0 && (
            <Alert severity="warning" sx={{ marginBottom: "16px" }}>
              With a $0 budget, services will stop when you consume your plan's
              credit limit.
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export { OverageInput };
