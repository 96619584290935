import { Mail } from "@mui/icons-material";
import { Alert, AlertColor, Box, Button, Stack } from "@mui/material";
import { BaseState } from "common/models";
import {
  InvitationList,
  InvitationListState,
} from "components/organisations/organisation-invitation-list";
import {
  IdentityState,
  Organisation,
  getActiveOrganisation,
  useIdentity,
} from "contexts/identity-context";
import { UserMembership } from "data/models";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { makeApiRequestPostToUrl, makeApiRequestToUrl } from "utils/api-client";
import { object, string } from "yup";
import { MemberInvitationModal } from "./invite-members";

export interface OrganisationInviteState extends BaseState {
  userEmail: OrganisationInvitation;
}

export type OrganisationInvitation = {
  userEmail: string;
  organisationId: string;
  invitationStatus: string;
};

interface AlertState {
  message: string;
  severity: AlertColor;
}

export const invitationValidator = object({
  userEmail: string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export type InvitationResponse = {
  userExisted: boolean;
};

const fetchOrganisationInvitations = async (
  currentOrganisation: Organisation | false,
  setInvitationsListState: Dispatch<SetStateAction<InvitationListState>>,
  identityState: IdentityState,
  setIdentityState: Dispatch<SetStateAction<IdentityState>>
) => {
  setInvitationsListState((prevState) => {
    return {
      ...prevState,
      message: "",
      isError: false,
      isLoading: true,
    };
  });
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/v2/organisation/membership?organisationId=${
        currentOrganisation
          ? encodeURIComponent(currentOrganisation.organisationId)
          : ""
      }`,
      "GET"
    );
    if (response.status !== 200) {
      setInvitationsListState((prevState) => {
        return {
          ...prevState,
          message:
            "An error occurred while fetching the organisation invitations",
          isError: true,
          isLoading: false,
        };
      });
    } else {
      const invitationList: UserMembership[] = await response.json();
      setInvitationsListState((prevState) => {
        return {
          ...prevState,
          message: "",
          invitationList: invitationList,
          isError: false,
          isLoading: false,
        };
      });
    }
  } catch (e) {
    setInvitationsListState((prevState) => {
      return {
        ...prevState,
        message: "",
        isError: true,
        isLoading: false,
      };
    });
  }
};

const submitInvitation = async (
  emailList: string[],
  currentOrganisation: Organisation | false,
  setState: Dispatch<SetStateAction<OrganisationInviteState>>,
  invitationsListState: InvitationListState,
  setInvitationsListState: Dispatch<SetStateAction<InvitationListState>>,
  identityState: IdentityState,
  setIdentityState: Dispatch<SetStateAction<IdentityState>>,
  setInvitationModalOpen: Dispatch<SetStateAction<boolean>>,
  setAlertStateList: Dispatch<SetStateAction<AlertState[]>>,
  setShowAlert: Dispatch<SetStateAction<boolean>>
) => {
  const alertStateList: AlertState[] = [];

  if (!currentOrganisation) {
    return;
  }
  setState((prevState) => {
    return {
      ...prevState,
      message: "",
      isLoading: true,
      isError: false,
    };
  });
  await Promise.all(
    emailList.map((email) =>
      makeApiRequestPostToUrl(
        `${import.meta.env.VITE_DELIVERY_API_URL}`,
        "/v2/organisation/membership",
        "POST",
        {
          userEmail: email,
          organisationId: currentOrganisation.organisationId,
          invitationStatus: "PENDING",
        }
      )
    )
  ).then((results) => {
    results.forEach(async (r, i) => {
      const res = await r.json();
      let message = res?.messages?.join(". ");
      const email = emailList[i];
      let severity: AlertColor = "info";
      if (r.status !== 200) {
        severity = "error";
      } else {
        message = res.userExisted
          ? `Invitation to ${email} has been sent. Invited user is already registered with Geoscape.`
          : `Invitation to ${email} has been sent. Invited user does not exist in Geoscape. They may need to sign up.`;
        severity = "info";
      }
      alertStateList.push({
        message: message,
        severity: severity,
      });
    });
  });

  const currentInvitations = invitationsListState.invitationList;
  fetchOrganisationInvitations(
    currentOrganisation,
    setInvitationsListState,
    identityState,
    setIdentityState
  );
  setInvitationsListState({
    message: "",
    invitationList: currentInvitations,
    isError: false,
    isLoading: false,
  });
  setState((prevState) => {
    return {
      ...prevState,
      isError: false,
      isLoading: false,
      message: "",
    };
  });
  setInvitationModalOpen(false);
  setShowAlert(true);
  setAlertStateList(alertStateList);
};

const openModal = (
  setState: Dispatch<SetStateAction<OrganisationInviteState>>,
  setInvitationModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  setState((prevState) => {
    return {
      ...prevState,
      message: "Invitations have been sent",
      userEmail: {
        userEmail: "",
        organisationId: "",
        invitationStatus: "PENDING",
      },
      isError: false,
      isLoading: false,
    };
  });
  setInvitationModalOpen(true);
};

export const OrganisationMembers = () => {
  const initialOrganisationInviteState = {
    message: "",
    userEmail: {
      userEmail: "",
      organisationId: "",
      invitationStatus: "PENDING",
    },
    isLoading: false,
    isError: false,
  };
  const initialInvitationListState: InvitationListState = {
    message: "",
    invitationList: [],
    isError: false,
    isLoading: true,
  };
  const [state, setState] = useState<OrganisationInviteState>(
    initialOrganisationInviteState
  );
  const [invitationsListState, setInvitationsListState] =
    useState<InvitationListState>(initialInvitationListState);
  const [identityState, setIdentityState] = useIdentity();
  const currentOrganisation: Organisation | false =
    getActiveOrganisation(identityState);

  const [invitationModalOpen, setInvitationModalOpen] = useState(false);
  const [alertStateList, setAlertStateList] = useState<AlertState[]>([
    { message: "", severity: "info" },
  ]);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    if (showAlert) {
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [showAlert]);

  useEffect(() => {
    fetchOrganisationInvitations(
      currentOrganisation,
      setInvitationsListState,
      identityState,
      setIdentityState
    );
  }, [identityState, setIdentityState]);

  return (
    <Box marginY={2}>
      <MemberInvitationModal
        isOpen={invitationModalOpen}
        isLoading={state.isLoading}
        isError={state.isError}
        handleClose={() => setInvitationModalOpen(false)}
        handleSubmit={(emailList: string[]) =>
          submitInvitation(
            emailList,
            currentOrganisation,
            setState,
            invitationsListState,
            setInvitationsListState,
            identityState,
            setIdentityState,
            setInvitationModalOpen,
            setAlertStateList,
            setShowAlert
          )
        }
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "relative",
          top: "-70px",
        }}
      >
        <Button
          onClick={() => openModal(setState, setInvitationModalOpen)}
          disabled={!currentOrganisation || state.isLoading}
          startIcon={<Mail />}
          variant="contained"
        >
          Invite Members
        </Button>
      </Box>
      {showAlert && renderAlerts(alertStateList)}
      <InvitationList
        invitationsState={invitationsListState}
        setInvitationsState={setInvitationsListState}
      />
    </Box>
  );
};

const renderAlerts = (alertStateList: AlertState[]) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      {alertStateList
        .filter((m) => m.message)
        .map((alert: AlertState, index) => (
          <Alert key={index} variant="filled" severity={alert.severity}>
            {alert.message}
          </Alert>
        ))}
    </Stack>
  );
};
