import * as QueryKeys from "data";

import EditIcon from "@mui/icons-material/Edit";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { DashboardCard } from "components/dashboard/dashboard-card";
import ConfirmationModal from "components/modals/confirmation-modal";
import { darkTheme } from "components/usage/dark";
import {
  fetchBillingUsage,
  fetchPaymentMethod,
  fetchSubscription,
} from "data/queries";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useIntercom } from "react-use-intercom";
import useDarkMode from "use-dark-mode";
import { resolveIdentityId } from "utils/identity";
import OverageSummary from "../overage/overageSummary";

import { useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import * as Routes from "routes";

import { formatInterval } from "utils/format";
import { formatTimestamp } from "utils/format-date";

import { useSnackbar } from "notistack";

import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { useIdentity } from "contexts/identity-context";
import { cancelFutureSubscription } from "data/mutations";
import * as echarts from "echarts";
import {
  buildDashboardCardPaymentMethod,
  buildDashboardCardRenewal,
  buildDashboardCardSubscription,
  buildDashboardCardUsage,
} from "../../../dashboard/utils-card";

import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { UsageGraph } from "components/usage/components/usage-graph/ UsageGraph";
import { UsageSkeleton } from "components/usage/components/usage-graph/UsageSkeleton";
import { useFetchUsageGraph } from "components/usage/fetch-hooks";

export const GenericSubscription = () => {
  const history = useHistory();
  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const darkMode = useDarkMode(false);

  // Queries and Mutations
  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription()
  );
  const billingUsageQuery = useQuery([QueryKeys.billingUsage, identityId], () =>
    fetchBillingUsage()
  );
  const paymentMethodQuery = useQuery(
    [QueryKeys.paymentMethod, identityId],
    () => fetchPaymentMethod()
  );

  const queryClient = useQueryClient();
  const futureSubscriptionMutation = useMutation(
    () => cancelFutureSubscription(),
    {
      onSuccess: () => {
        queryClient.setQueryData([QueryKeys.subscription, identityId], {
          ...subscriptionQuery.data,
          activeSubscription: {
            ...subscriptionQuery.data?.activeSubscription,
            nextSub: undefined,
          },
        });
        setCancelFutureSubscriptionModalIsOpen(false);
        enqueueSnackbar(
          "You successfully cancelled your pending subscription downgrade.",
          {
            variant: "success",
          }
        );
      },
    }
  );

  // Start Subscription
  const dailyUsageStartTime =
    subscriptionQuery.isSuccess && !!subscriptionQuery.data.activeSubscription
      ? subscriptionQuery.data.activeSubscription.currentPeriodStart
      : 1;
  // End Subscription
  const dailyUsageEndTime =
    subscriptionQuery.isSuccess && !!subscriptionQuery.data.activeSubscription
      ? subscriptionQuery.data.activeSubscription.renewalDate
      : 1;

  // NEW GRAPH
  const enabled = !!subscriptionQuery.data;
  const usageQuery = useFetchUsageGraph(
    identityState,
    dailyUsageStartTime * 1000,
    dailyUsageEndTime * 1000,
    enabled,
    "1d"
  );

  const isLoading: boolean =
    usageQuery.isLoading ||
    subscriptionQuery.isLoading ||
    billingUsageQuery.isLoading ||
    paymentMethodQuery.isLoading;

  const isEnterprise: boolean =
    subscriptionQuery.isSuccess &&
    !!subscriptionQuery.data.activeSubscription &&
    subscriptionQuery.data.activeSubscription.plan.isEnterprise;

  const isPaymentMyob =
    (subscriptionQuery.isSuccess &&
      !!subscriptionQuery.data.activeSubscription &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      !!subscriptionQuery.data.activeSubscription &&
      subscriptionQuery.data.activeSubscription.isMyob);

  const isSuccess: boolean =
    usageQuery.isSuccess &&
    subscriptionQuery.isSuccess &&
    billingUsageQuery.isSuccess &&
    paymentMethodQuery.isSuccess;

  const isError: boolean = subscriptionQuery.isError;

  const { show } = useIntercom();

  const creditAmount: number = billingUsageQuery.data
    ? billingUsageQuery.data.allowance
    : 0;

  const dashboardCardRenewal = buildDashboardCardRenewal(
    subscriptionQuery,
    isLoading,
    history
  );

  const dashboardCardPaymentMethod = buildDashboardCardPaymentMethod(
    paymentMethodQuery,
    isLoading,
    isEnterprise,
    isPaymentMyob,
    history
  );

  const dashboardCardUsage = buildDashboardCardUsage(
    billingUsageQuery,
    creditAmount,
    isLoading,
    history
  );

  const dashboardCardSubscription = buildDashboardCardSubscription(
    subscriptionQuery,
    history,
    isLoading,
    isEnterprise,
    isPaymentMyob
  );

  const hasScheduledDowngrade = subscriptionQuery.data
    ? !!subscriptionQuery.data.activeSubscription &&
      subscriptionQuery.data.activeSubscription.nextSub != null
    : true;

  const [
    cancelFutureSubscriptionModalIsOpen,
    setCancelFutureSubscriptionModalIsOpen,
  ] = useState(false);

  const { enqueueSupportSnackbar } = useCustomSnackbars();
  const chartRef = useRef(null);
  echarts.registerTheme("darkTheme", darkTheme);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          position: "absolute",
          top: "-55px",
          right: "0px",
        }}
      >
        <Button
          onClick={() => {
            if (isEnterprise || isPaymentMyob) {
              enqueueSupportSnackbar(
                "To manage your subscription, please contact support."
              );
            } else {
              history.push(Routes.orgManageSubscription);
            }
          }}
          variant="contained"
          disabled={
            hasScheduledDowngrade ||
            subscriptionQuery.isLoading ||
            !subscriptionQuery.data?.activeSubscription
          }
          size="large"
          startIcon={<EditIcon />}
        >
          Manage Subscription
        </Button>
      </Box>
      {subscriptionQuery.isSuccess &&
        !!subscriptionQuery.data.activeSubscription &&
        subscriptionQuery.data.activeSubscription.nextSub &&
        isSuccess && (
          <>
            <Alert
              severity="info"
              action={
                <Button
                  size="small"
                  onClick={() => {
                    futureSubscriptionMutation.reset();
                    setCancelFutureSubscriptionModalIsOpen(true);
                  }}
                >
                  Cancel scheduled change
                </Button>
              }
            >
              <AlertTitle>Scheduled downgrade</AlertTitle>
              You have a scheduled downgrade from{" "}
              {subscriptionQuery.data.activeSubscription.plan.name}{" "}
              {formatInterval(
                subscriptionQuery.data.activeSubscription.plan.interval
              )}{" "}
              to {subscriptionQuery.data.activeSubscription.nextSub.plan.name}{" "}
              {formatInterval(
                subscriptionQuery.data.activeSubscription.nextSub.plan.interval
              )}{" "}
              that will take effect on the{" "}
              {formatTimestamp(
                subscriptionQuery.data.activeSubscription.nextSub.startTime *
                  1000
              )}
              . Until then you are still able to use all of the features of{" "}
              {subscriptionQuery.data.activeSubscription.plan.name}.
            </Alert>

            <ConfirmationModal
              isLoading={futureSubscriptionMutation.isLoading}
              isError={futureSubscriptionMutation.isError}
              open={cancelFutureSubscriptionModalIsOpen}
              header={`Confirm Cancellation of Downgrade`}
              body={`Are you sure you want to cancel the scheduled downgrade? When cancelled, your previous subscription of ${
                subscriptionQuery.data.activeSubscription.plan.name
              } ${formatInterval(
                subscriptionQuery.data.activeSubscription.plan.interval
              )} will renew on ${formatTimestamp(
                subscriptionQuery.data.activeSubscription.renewalDate * 1000
              )}.`}
              leftButtonText={"Confirm"}
              leftButtonColor="primary"
              rightButtonText={"Cancel"}
              rightButtonColor="warning"
              handleLeftButton={() => futureSubscriptionMutation.mutate()}
              handleRightButton={() => {
                setCancelFutureSubscriptionModalIsOpen(false);
              }}
              handleClose={() => setCancelFutureSubscriptionModalIsOpen(false)}
            />
          </>
        )}

      <ReactQueryErrorWrapper
        queries={[subscriptionQuery, billingUsageQuery, paymentMethodQuery]}
        mutations={[futureSubscriptionMutation]}
      />

      {/* Cards */}
      <Box
        style={{
          display: "flex",
          width: "100%",
          gap: "16px",
        }}
      >
        <DashboardCard
          cardState={dashboardCardRenewal}
          disableActionBtn={true}
        />

        <DashboardCard
          cardState={dashboardCardSubscription}
          disableActionBtn={
            hasScheduledDowngrade || !subscriptionQuery.data?.activeSubscription
          }
        />

        <DashboardCard cardState={dashboardCardPaymentMethod} />

        <DashboardCard cardState={dashboardCardUsage} />
      </Box>

      <OverageSummary
        renderIfEnterprise={true}
        showAlerts={true}
        showActions={true}
      />

      <UsageSkeleton isLoading={isLoading} />
      {isSuccess && (
        <UsageGraph
          startTime={dailyUsageStartTime}
          endTime={dailyUsageEndTime}
          isLoading={usageQuery.isLoading}
          isError={usageQuery.isError}
          isSuccess={usageQuery.isSuccess}
          usageQuery={usageQuery}
          hideLegend={true}
          creditAmount={creditAmount}
          subheader="Cumulative Daily credit usage over your current billing period"
        />
      )}
    </Box>
  );
};
