import { Alert, Card, LinearProgress, Typography } from "@mui/material";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { DemoConfiguration } from "data/models";
import { fetchDemos } from "data/queries";
import { DataDemos } from "pages/geoscape-demos/demo-page/demos-page";
import { useQuery } from "react-query";
import { Route } from "react-router";
import { resolveIdentityId } from "utils/identity";

export const DemoRoutes = ({ component, ...rest }: any) => {
  const [identityState] = useIdentity();

  const identityId = resolveIdentityId(identityState);

  const { enqueueQueryFailed } = useCustomSnackbars();

  const demosQuery = useQuery(
    [QueryKeys.demosKey, identityId],
    () => fetchDemos(),
    {
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );

  const getDemoByPath = (path: string) => {
    return DataDemos.find((item) => item.path === path);
  };

  const hasAccess = (path: string, demosAccessList: DemoConfiguration[]) => {
    return demosAccessList.find((item) => item.id === getDemoByPath(path)?.id);
  };
  const Component = component;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        demosQuery.isSuccess ? (
          hasAccess(rest.path, demosQuery.data) ? (
            <Component />
          ) : (
            <ErrorMessage />
          )
        ) : (
          <div style={{ position: "absolute", width: "100%" }}>
            <LinearProgress />
          </div>
        )
      }
    />
  );
};

const ErrorMessage = () => {
  return (
    <>
      <Card style={{ width: "100%", padding: "20px" }}>
        <Alert severity="warning" variant="outlined" style={{}}>
          {/* <AlertTitle> */}
          <Typography variant="body2">
            You don't have permission to view this demo. Please contact support.
          </Typography>
        </Alert>
      </Card>
    </>
  );
};
