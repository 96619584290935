import {
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRange,
  DateRangeValidationError,
} from "@mui/x-date-pickers-pro/models";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { useIdentity } from "contexts/identity-context";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { shortcutsItems } from "./utils";

interface DateRangePickerProps {
  onChange: (
    value: DateRange<Dayjs>,
    context: PickerChangeHandlerContext<DateRangeValidationError>
  ) => void;
  isFreemium: boolean;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const [identityState] = useIdentity();

  const isFreemium = props.isFreemium;

  const today = dayjs();
  const thirtyDaysAgo = today.subtract(30, "day");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
        calendars={2}
        onChange={props.onChange}
        minDate={isFreemium ? thirtyDaysAgo : undefined}
        maxDate={today}
      />
    </LocalizationProvider>
  );
};

dayjs.extend(quarterOfYear);
