import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import {
  fetchAddressById,
  fetchBuildingsByIdentifier,
  fetchLandParcelsCadastresId,
} from "data/queries";
import { useRef, useState } from "react";
import Draggable from "react-draggable";
import { MapboxGeoJSONFeature } from "react-map-gl";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { EasementsTable } from "./details/easements-table";
import { KeyValueDetails } from "./details/key-value-details";

interface FeatureCardProps {
  intersectingEasements: MapboxGeoJSONFeature[] | undefined;
  cadastrePid: string;
  addressId: string;
  onClose: () => void;
}

export const FeatureCard = (props: FeatureCardProps) => {
  const [identityState] = useIdentity();

  const identityId = resolveIdentityId(identityState);

  const initialPosition = {
    x: Math.min(window.innerWidth * 0.7, window.innerWidth - 1000),
    y: window.innerHeight * 0.1,
  };
  const [position, setPosition] = useState(initialPosition);
  const nodeRef = useRef(null);

  const addressByIdQuery = useQuery(
    [QueryKeys.addressById, props.addressId],
    () => fetchAddressById(props.addressId),
    {
      enabled: !!props.addressId,
    }
  );

  const buildingsQuery = useQuery(
    [QueryKeys.buildingsKey, identityId, "cadastre", props.cadastrePid],
    () =>
      fetchBuildingsByIdentifier("cadastrePid", props.cadastrePid, [
        "poolIndicator",
        "solarIndicator",
      ]),
    {
      enabled: !!props.cadastrePid,
    }
  );

  const landParcelsCadastresQuery = useQuery(
    [QueryKeys.landParcelsCadastresKey, identityId, props.cadastrePid],
    () => fetchLandParcelsCadastresId(props.cadastrePid),
    {
      enabled: !!props.cadastrePid,
    }
  );

  return (
    <Draggable
      nodeRef={nodeRef}
      position={position}
      onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
    >
      <Card sx={{ width: 600 }} ref={nodeRef} className="draggable">
        <CardHeader
          ref={nodeRef}
          sx={{
            border: "none",
            cursor: "move",
            padding: 1,
          }}
          title={addressByIdQuery.data?.address.properties.formatted_address}
          action={
            <IconButton onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent ref={nodeRef} sx={{ width: "100%" }}>
          <Box display="flex" flexDirection="column" gap={1} width="100%">
            <Box display="flex" flexDirection="row" gap={1} width="100%">
              {landParcelsCadastresQuery.isError && (
                <Alert severity="error">
                  <AlertTitle>Parcel Details not found.</AlertTitle>
                </Alert>
              )}
              {landParcelsCadastresQuery.isLoading && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                ></Skeleton>
              )}
              {landParcelsCadastresQuery.isSuccess && (
                <KeyValueDetails
                  header="Parcel Details"
                  countText={`Parcel Count: ${landParcelsCadastresQuery.data.features.length}`}
                  properties={{
                    "Parcel Id":
                      landParcelsCadastresQuery.data.features[0].properties
                        ?.parcelId,
                    "Parcel Type":
                      landParcelsCadastresQuery.data.features[0].properties
                        ?.parcelType,
                    "Title Status":
                      landParcelsCadastresQuery.data.features[0].properties
                        ?.titleStatus,
                    "Contributor Id":
                      landParcelsCadastresQuery.data.features[0].properties
                        ?.contributorId,
                    "Cadastre Pid":
                      landParcelsCadastresQuery.data.features[0].properties
                        ?.cadastrePid,
                    "Area": `${landParcelsCadastresQuery.data.features[0].properties?.area} m²`,
                  }}
                />
              )}
              {buildingsQuery.isSuccess && (
                <KeyValueDetails
                  header="Primary Building Details"
                  countText={`Building Count: ${buildingsQuery.data.features.length}`}
                  properties={{
                    "Ground Elevation":
                      buildingsQuery.data.features[0].properties
                        ?.groundElevation,
                    "Swimming Pools":
                      buildingsQuery.data.features[0].properties?.poolIndicator
                        ?.swimmingPoolAdjacent,
                    "Solar Panels":
                      buildingsQuery.data.features[0].properties?.solarIndicator
                        ?.solarPanel,
                    "Planning Zone":
                      buildingsQuery.data.features[0].properties?.planningZone,
                    "Building Pid":
                      buildingsQuery.data.features[0].properties?.buildingPid,
                    "Area": `${buildingsQuery.data.features[0].properties?.area} m²`,
                  }}
                />
              )}
            </Box>
            {props.intersectingEasements &&
              props.intersectingEasements.length > 0 && (
                <Box gap={1}>
                  <Alert severity="warning" variant="outlined">
                    <AlertTitle>Parcel contains easements</AlertTitle>
                  </Alert>
                  <EasementsTable
                    intersectingEasements={props.intersectingEasements}
                  />
                </Box>
              )}
          </Box>
        </CardContent>
      </Card>
    </Draggable>
  );
};
