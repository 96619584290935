import { LoadingButton } from "@mui/lab";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { createBatchConfig } from "data/mutations";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { BatchConfig } from "../models";
import { HistoryState } from "../types";
import { batchOutputGeocode } from "../utils";
import { DefineBatchJob } from "./step-define-batch-job/define-batch-job";
import { DefineOutputFileMappings } from "./step-define-output-file-mappings/define-output-file-mappings-page";
import { ViewSampleBatchJob } from "./step-preview-sample/view-sample-batch-job";
import { StartBatchJob } from "./step-start-batch-job/start-batch-job";
import { UploadInputFile } from "./step-upload-input-file/upload-input-file-page";

export const CreateBatchPage = () => {
  const history = useHistory();
  const { batchId, seedBatchConfig } = history.location.state as HistoryState; // Generate the batch id in advance for file prefix
  const [identityState] = useIdentity();

  const identityId = resolveIdentityId(identityState);

  const queryClient = useQueryClient();

  var initialBatchConfig: BatchConfig = {
    displayName: "",
    type: "addressGeocoder",
    batchInfo: {
      batchId: batchId,
    },
    parameters: {
      matchType: "",
      stateFilter: [],
      dataset: [],
      additionalProperties: [],
    },
    hubInfo: {
      activeStep: 0,
      csvHeaders: [],
      files: [],
      batchConfigHash: "",
      checkedItems: batchOutputGeocode.properties,

      headersMessageAck: false,
      sample: {
        rows: [],
        request: [],
        response: [],
      },
    },
    input: {
      format: [],
      file: "",
    },
    output: {
      // format: batchOutputGeocode.output,
      format: [],
    },
    returnDownloadLink: true,
    status: "Draft",
    isLoading: false,
  };

  // Batch config for the new job
  const [batchConfig, setBatchConfig] = useState<BatchConfig>(
    seedBatchConfig ? seedBatchConfig : initialBatchConfig
  );

  if (import.meta.env.VITE_ENVIRONMENT !== "production") {
    console.log("batch config", batchConfig);
  }

  const [activeStep, setActiveStep] = useState(batchConfig.hubInfo.activeStep);

  if (activeStep != batchConfig.hubInfo.activeStep) {
    setBatchConfig((prev) => ({
      ...prev,
      hubInfo: {
        ...prev.hubInfo,
        activeStep: activeStep,
      },
      status: activeStep != 4 ? "Draft" : prev.status,
    }));
  }

  const batchesCreateMutation = useMutation(
    (values: any) => createBatchConfig(values.batchConfig),
    {
      onSuccess: (data, variables, context) => {
        if (variables.navigate) {
          queryClient.removeQueries([QueryKeys.batchesKey, identityId]);
          history.push(Routes.batchBatches);
        }

        if (variables.nextStep) {
          setActiveStep(variables.nextStep);
        }
      },
    }
  );

  const stepsMapping = [
    {
      stepName: "Job",
      element: DefineBatchJob,
    },
    {
      stepName: "Input",
      element: UploadInputFile,
    },
    {
      stepName: "Sample",
      element: ViewSampleBatchJob,
    },
    {
      stepName: "Output",
      element: DefineOutputFileMappings,
    },
    {
      stepName: "Start",
      element: StartBatchJob,
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          top: "-55px",
          right: "0px",
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={async () => {
            batchesCreateMutation.mutate({
              batchConfig: batchConfig,
              navigate: true,
            });
          }}
          loading={batchesCreateMutation.isLoading}
        >
          Save and Exit
        </LoadingButton>
      </Box>

      <ReactQueryErrorWrapper
        queries={[]}
        mutations={[batchesCreateMutation]}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Stepper activeStep={activeStep}>
          {stepsMapping.map((step, index) => {
            return (
              <Step key={step.stepName}>
                <StepLabel>{step.stepName}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {React.createElement(stepsMapping[activeStep].element, {
          activeStep: activeStep,
          setActiveStep: setActiveStep,
          maxSteps: stepsMapping.length - 1,
          batchConfig: batchConfig,
          setBatchConfig: setBatchConfig,
          saveBatchConfig: async (config?: BatchConfig, nextStep?: number) => {
            const saveConfig = config ?? batchConfig;

            return await batchesCreateMutation.mutateAsync({
              batchConfig: saveConfig,
              navigate: false,
              nextStep: nextStep,
            });
          },
          isSaveBatchConfigLoading: batchesCreateMutation.isLoading,
        })}
      </Box>
    </Box>
  );
};
