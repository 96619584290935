import { Button, Chip } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CustomDataset, CustomDatasetList } from "data/models";
import { fetchCustomDatasetsLink } from "data/queries";
import { formatBytes } from "utils/commons";

import { styled } from "@mui/styles";

import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

const ResponsiveButton = styled(Button)({
  "@media (max-width: 1370)": {
    fontSize: "10px",
  },
  "@media (max-width: 1500px)": {
    fontSize: "10px",
  },
  "@media (min-width: 1500px)": {
    fontSize: "12px",
  },
});

type Dictionary = {
  [key: string]: string;
};

const DisplayFormatMapping: Dictionary = {
  GDB: "GDB",
  GEOJSON: "GeoJSON",
  SHP: "Shapefile",
  TAB: "MapInfo TAB",
  LYNX: "LYNX",
  PUMP: "PUMP",
  GEOTIFF: "GEOTIFF",
  PSV: "Pipe-Separated Values",
};

const DisplayStateMapping: Dictionary = {
  "ALL STATES": "All States",
  AUSTRALIA: "Australia",
};

type params = {
  id: string;
  field: string;
  value: string;
};

function convertBytesToKb(kb: number) {
  const gb = kb / (1024 * 1024);
  return gb.toFixed(2) + " KB";
}

function formatDate(date: string) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [year, month, day] = date.split("-");
  const monthIndex = parseInt(month) - 1;

  const formattedDate = `${parseInt(day)} ${months[monthIndex]}, ${parseInt(
    year
  )}`;
  return formattedDate;
}

const customColumns = (): GridColDef[] => {
  const { enqueueNotImplementedSnackbar } = useCustomSnackbars();

  return [
    {
      flex: 1.5,
      field: "name",
      headerName: "Name",
    },

    {
      flex: 1,
      field: "region",
      headerName: "Regions",
      valueFormatter(params) {
        return DisplayStateMapping[params as string] ?? (params as string);
      },
    },
    {
      flex: 1,
      field: "format",
      headerName: "Format",
      valueFormatter(params) {
        return DisplayFormatMapping[params as string] ?? (params as string);
      },
    },
    {
      flex: 1,
      field: "datum",
      headerName: "Datum",
    },
    {
      flex: 1.2,
      field: "release",
      headerName: "Release Date",
      valueFormatter(params) {
        const [year, month] = (params as string).split("-").map(Number);
        const monthName = new Date(year, month - 1).toLocaleString("default", {
          month: "long",
        });
        return `${monthName} ${year}`;
      },
    },
    {
      flex: 1,
      field: "publishDate",
      headerName: "Publish Date",
      valueFormatter(params) {
        return formatDate(params as string);
      },
    },
    {
      flex: 0.8,
      field: "version",
      headerName: "Version",

      renderCell: (params) => {
        return <Chip label={params.value} />;
      },
    },

    {
      flex: 1,
      field: "size",
      headerName: "File Size",
      valueFormatter(params) {
        return formatBytes(params as number);
      },
    },

    {
      flex: 1.2,
      field: "id",
      headerName: "Download",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Button
              variant="contained"
              style={{
                display: "flex",
                margin: "5px",
                alignContent: "center",
                alignItems: "center",
              }}
              onClick={async () => {
                try {
                  const response = await fetchCustomDatasetsLink(params.value);

                  const a = document.createElement("a");
                  a.href = response;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(response);
                } catch (er) {
                  enqueueNotImplementedSnackbar();
                  // If this happens it will show and popup
                }
              }}
            >
              <FileDownloadIcon />
            </Button>
          </>
        );
      },
    },
  ];
};

const customersToRows = (
  datasetsList: CustomDatasetList
): GridValidRowModel[] => {
  const rows = datasetsList.datasets.map((key: CustomDataset) => {
    return {
      id: key.id,
      name: key.name,
      region: key.region,
      format: key.format,
      size: key.size,
      release: key.release,
      version: key.version,
      publishDate: key.publishDate,
      licenced: key.licenced,
      datum: key.datum,
      status: key.status,
    };
  });
  return rows;
};

export { customColumns, customersToRows };
