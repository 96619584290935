import { CognitoUser } from "@aws-amplify/auth";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { MfaSetup } from "components/user/mfa-setup";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { DeleteConfirmationModel } from "./delete-confirmation";

export const UserSecurity = () => {
  type MfaMethodType =
    | "NOMFA"
    | "TOTP"
    | "SMS"
    | "SMS_MFA"
    | "SOFTWARE_TOKEN_MFA";

  const [user, setUser] = useState<CognitoUser | null>(null);
  const [mfaEnabled, setMfaEnabled] = useState(false);

  const [totpEnabled, setTotpEnabled] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);

  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState);
  const [open, setOpen] = useState(false);

  const [smsDeleteModalOpen, setSmsDeleteModalOpen] = useState(false);
  const [totpDeleteModalOpen, setTotpDeleteModalOpen] = useState(false);

  const [startFromStep, setStartFromStep] = useState(0); // 2 - TOTP (-2) - SMS

  const queryClient = useQueryClient();

  const authenticatedUserQuery = useQuery(
    [QueryKeys.authenticatedUser, identityId],
    () =>
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      }),
    {
      onSuccess: (cognitoUser: CognitoUser) => {
        setUser(cognitoUser);
        cognitoUser?.getUserData((error: any, userData: any) => {
          if (userData["UserMFASettingList"]) {
            setTotpEnabled(
              userData["UserMFASettingList"].includes("SOFTWARE_TOKEN_MFA")
            );
            setSmsEnabled(userData["UserMFASettingList"].includes("SMS_MFA"));
          }
        });
      },
    }
  );

  const setPreferredMFAMutation = useMutation((mfaType: MfaMethodType) =>
    Auth.setPreferredMFA(user, mfaType)
  );

  const preferredMfaQuery = useQuery(
    [QueryKeys.preferredMfa, identityId],
    () =>
      Auth.getPreferredMFA(user, {
        bypassCache: true,
      }),
    {
      onSuccess: (mfaType: MfaMethodType) => {
        setMfaEnabled(mfaType !== "NOMFA");
        if (mfaType === "NOMFA") {
          setSmsEnabled(false);
          setTotpEnabled(false);
        }
      },
      enabled: !!user,
    }
  );

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries([QueryKeys.preferredMfa, identityId]);
    await queryClient.invalidateQueries([
      QueryKeys.authenticatedUser,
      identityId,
    ]);
  };

  const isLoading =
    authenticatedUserQuery.isLoading || preferredMfaQuery.isLoading;
  const isError = authenticatedUserQuery.isError || preferredMfaQuery.isError;
  const isSuccess =
    authenticatedUserQuery.isSuccess && preferredMfaQuery.isSuccess;

  const style: React.CSSProperties = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 468,
    backgroundColor: "background.paper",
    padding: 1,
    display: "flex",
    flexDirection: "column",
  };

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = async (event: SelectChangeEvent) => {
    await setPreferredMFAMutation.mutateAsync(
      event.target.value as MfaMethodType
    );
    await queryClient.invalidateQueries([QueryKeys.preferredMfa, identityId]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
        <Typography variant="h5">Multi Factor Authentication</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        {isSuccess && (
          <Card>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <FormControl fullWidth>
                <InputLabel id="mfa-method-select-label">
                  Preferred MFA method
                </InputLabel>
                <Select
                  disabled={setPreferredMFAMutation.isLoading || !mfaEnabled}
                  labelId="mfa-method-select-label"
                  id="mfa-method-select"
                  value={preferredMfaQuery.data}
                  onChange={handleSelectChange}
                  fullWidth
                  size="small"
                  label="Preferred MFA method"
                >
                  <MenuItem value="SOFTWARE_TOKEN_MFA" disabled={!totpEnabled}>
                    Authenticator App (TOTP)
                  </MenuItem>
                  <MenuItem value="SMS_MFA" disabled={!smsEnabled}>
                    SMS/Text Message
                  </MenuItem>
                  <MenuItem value="NOMFA" disabled>
                    No Multi Factor Authentication
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography variant="subtitle1">Methods</Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
              >
                <PhoneIphoneIcon />
                <Typography variant="body2">
                  Authenticator App (TOTP)
                </Typography>
                {totpEnabled && (
                  <Chip
                    label="Configured"
                    variant="outlined"
                    size="small"
                    color="success"
                  />
                )}
                <Box display="flex" flex={1} justifyContent="flex-end">
                  {totpEnabled && (
                    <LoadingButton
                      loading={isLoading}
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => setTotpDeleteModalOpen(true)}
                    >
                      Delete
                    </LoadingButton>
                  )}
                  {!totpEnabled && (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => {
                        setStartFromStep(2); // TOTP
                        handleOpen();
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Box>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
              >
                <MailOutlineIcon />
                <Typography variant="body2">SMS/Text Message</Typography>
                {smsEnabled && (
                  <Chip
                    label="Configured"
                    variant="outlined"
                    size="small"
                    color="success"
                  />
                )}
                <Box display="flex" flex={1} justifyContent="flex-end">
                  {smsEnabled && (
                    <LoadingButton
                      loading={isLoading}
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => setSmsDeleteModalOpen(true)}
                    >
                      Delete
                    </LoadingButton>
                  )}
                  {!smsEnabled && (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => {
                        setStartFromStep(-2); // SMS
                        handleOpen();
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Box>
              </Stack>
              {!mfaEnabled && (
                <Alert severity="warning" variant="outlined">
                  Multi Factor Authentication is not configured on your profile
                </Alert>
              )}
            </CardContent>
          </Card>
        )}
        {isLoading && (
          <Card>
            <CardContent>
              <Skeleton width={550} height={50} />
              <Skeleton width={550} height={100} />
            </CardContent>
          </Card>
        )}
        {isError && (
          <Card>
            <CardContent>
              <Alert variant="outlined" severity="error">
                An error occured while getting the MFA settings.
              </Alert>
            </CardContent>
          </Card>
        )}
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            {user && (
              <MfaSetup
                style={style}
                cognitoUser={user}
                onOptOut={handleClose}
                onSubmit={async () => {
                  handleClose();
                  setMfaEnabled(true);
                  invalidateQueries();
                  setStartFromStep(0);
                }}
                startFromStep={startFromStep}
              ></MfaSetup>
            )}
          </Box>
        </Modal>
        <DeleteConfirmationModel
          open={smsDeleteModalOpen}
          onClose={() => setSmsDeleteModalOpen(false)}
          onDelete={async () => {
            user?.setUserMfaPreference(
              {
                PreferredMfa: false,
                Enabled: false,
              },
              null,
              (error, cb) => {}
            );
            invalidateQueries();
            await setPreferredMFAMutation.mutateAsync(
              totpEnabled ? "SOFTWARE_TOKEN_MFA" : "NOMFA"
            );
            await queryClient.invalidateQueries([
              QueryKeys.preferredMfa,
              identityId,
            ]);
            setSmsEnabled(false);
            setSmsDeleteModalOpen(false);
          }}
        />
        <DeleteConfirmationModel
          open={totpDeleteModalOpen}
          onClose={() => setTotpDeleteModalOpen(false)}
          onDelete={async () => {
            user?.setUserMfaPreference(
              null,
              {
                PreferredMfa: false,
                Enabled: false,
              },
              (error, cb) => {}
            );
            invalidateQueries();
            await setPreferredMFAMutation.mutateAsync(
              smsEnabled ? "SMS_MFA" : "NOMFA"
            );
            await queryClient.invalidateQueries([
              QueryKeys.preferredMfa,
              identityId,
            ]);
            setTotpEnabled(false);
            setTotpDeleteModalOpen(false);
          }}
        />
      </Grid>
    </Grid>
  );
};
