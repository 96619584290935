import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchIntercomIdentityHash } from "data/queries";
import { useQuery } from "react-query";
import {
  DataAttributesCompany,
  IntercomProps,
  useIntercom,
} from "react-use-intercom";
import { resolveIdentityId } from "utils/identity";
import {
  IdentityState,
  Organisation,
  useIdentity,
} from "../../contexts/identity-context";

export const Intercom = () => {
  const { boot, update } = useIntercom();

  const [identityState] = useIdentity();

  const identityId = resolveIdentityId(identityState);

  const { enqueueQueryFailed } = useCustomSnackbars();

  const intercomIdentityHashQuery = useQuery(
    [QueryKeys.intercomIdentityHash, identityId],
    () => fetchIntercomIdentityHash(),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        bootIntercom(identityState, boot, update, data);
      },
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );
  return null;
};

function bootIntercom(
  identityState: IdentityState,
  boot: (props?: IntercomProps) => void,
  update: (props?: Partial<IntercomProps>) => void,
  intercomIdentityHash: string
): void {
  if (identityState && identityState !== "loading" && intercomIdentityHash) {
    let currentCompany: DataAttributesCompany = { companyId: "" };
    let companyList: DataAttributesCompany[] = [];
    identityState.organisations.forEach((organisation: Organisation) => {
      const company: DataAttributesCompany = {
        companyId: organisation.organisationId,
        name: organisation.name,
      };
      if (organisation.active) {
        currentCompany = company;
      }
      companyList.push(company);
    });
    boot({
      name: identityState.firstName + " " + identityState.lastName,
      email: identityState.email,
      userId: identityState.cognitoSub,
      companies: companyList,
      userHash: intercomIdentityHash,
      customAttributes: {
        first_name: identityState.firstName,
        last_name: identityState.lastName,
        current_organisation:
          currentCompany.companyId !== ""
            ? currentCompany.name
            : "Logged in as a user",
        stripe_id: "STRIPE_ID",
        stripe_plan: "STRIPE_PLAN",
      },
    });
    if (currentCompany.companyId !== "") {
      update({
        company: currentCompany,
      });
    }
    if (intercomIdentityHash) {
      update({
        userHash: intercomIdentityHash,
      });
    }
  }
}
